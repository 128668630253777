import React from 'react';
import artworks from '../datas/Artworks.js';
import AudioDetailsView from './AudioDetailsView';
import Feature2DetailsView from './Feature2DetailsView';
import VideoDetailsView from './VideoDetailsView';
import NotFound from '../views/NotFound';

import { useParams } from "react-router-dom";

export default function KioskDetailsView(props) {
	const artwork = artworks[useParams().id-1];


	if(artwork === undefined) {
	    return (
	      <NotFound />
	      )
	}

	if(artwork != null && artwork.interactive === 2) {
		return (<Feature2DetailsView artwork = {artwork} />);
	}
	if(artwork != null &&  artwork.videoPath !== undefined) {
		return (<VideoDetailsView artwork = {artwork} isKiosk={true} />);
	}


	return (<AudioDetailsView artwork = {artwork}  isKiosk={true} />);

}



