import React from 'react';
import { useState, useEffect } from 'react';
import Header from "../components/Header";
import Div100vh from 'react-div-100vh';
import DeviceOrientation, { Orientation } from 'react-screen-orientation';
import Plx from 'react-plx';
import { useNavigate } from "react-router-dom";
import animateScroll from 'animated-scroll-to';



const titleData = [
  {
    start: 'self',
    startOffset: 350,
    duration: 200,
    properties: [
    {
          startValue: 1,
          endValue: 0,
          property: 'opacity',
    },
    {
          startValue: 1,
          endValue: 2,
          property: 'scale',
    }
    ],
  },
];




const scrollDownData= [
  {
    start: 'self',
    startOffset: 100,
    duration: 100,
    properties: [
    {
          startValue: 1,
          endValue: 0,
          property: 'opacity',
    }
    ],
  },
];

const scrollDownData2= [
  {
    start: '.trigger-image-1',
    startOffset: '20vh',
    duration: 100,
    properties: [
    {
          startValue: 0,
          endValue: 1,
          property: 'opacity',
    }
    ],
  },
  {
    start: '.trigger-image-1',
    startOffset: '60vh',
    duration: 100,
    properties: [
    {
          startValue: 1,
          endValue: 0,
          property: 'opacity',
    }
    ],
  },
];

const title1Data = [
	{
		start: '.trigger-image-1',
		duration: '20vh',
		properties: [
		  {
		    startValue: 0,
		    endValue: 1,
		    property: 'opacity',
		  },
		],
	}
	,
	{
		start: '.trigger-image-1',
		startOffset: '50vh',
		duration: '20vh',
		properties: [
		  
		  {
		    startValue: 1,
		    endValue: 0,
		    property: 'opacity',
		  },
		  {
		    startValue: 0,
		    endValue: -100,
		    unit:"%",
		    property: 'translateY',
		  },
		],
	},
];

const title2Data = [
	{
		start: '.trigger-image-1',
		duration: '20vh',
		startOffset: '60vh',
		properties: [
		  {
		    startValue: 0,
		    endValue: 1,
		    property: 'opacity',
		  },
		  
		],
	}
	,
	{
		start: '.trigger-image-1',
		startOffset: '100vh',
		duration: '30vh',
		properties: [
		  
		  {
		    startValue: 1,
		    endValue: 0,
		    property: 'opacity',
		  },
		  {
		    startValue: 0,
		    endValue: -100,
		    unit:"%",
		    property: 'translateY',
		  },
		],
	},
];

const title3Data = [
	{
		start: '.trigger-image-1',
		duration: '20vh',
		startOffset: '110vh',
		properties: [
		  {
		    startValue: 0,
		    endValue: 1,
		    property: 'opacity',
		  },
		],
	}
	,
	{
		start: '.trigger-image-1',
		startOffset: '180vh',
		duration: '30vh',
		properties: [
		  
		  {
		    startValue: 1,
		    endValue: 0,
		    property: 'opacity',
		  },
		  {
		    startValue: 0,
		    endValue: -100,
		    unit:"%",
		    property: 'translateY',
		  },
		],
	},
];



const image1Data = [
	{
		start: '.trigger-image-1',
		startOffset: '10vh',
		duration: '20vh',
		properties: [
		  {
		    startValue: 0,
		    endValue: 1,
		    property: 'opacity',
		  },
		],
	},
	{
		start: '.trigger-image-1',
		startOffset: '180vh',
		duration: '30vh',
		properties: [
		  {
		    startValue: 1,
		    endValue: 0,
		    property: 'opacity',
		  },
		],
	},
]


const image1ZommedData = [
	{
		start: '.trigger-image-1',
		startOffset: '60vh',
		duration: '20vh',
		properties: [
		  {
	          startValue: 1,
	          endValue: 2,
	          property: 'scale',
		   },
		],
	},
	{
		start: '.trigger-image-1',
		startOffset: '110vh',
		duration: '20vh',
		properties: [
		 {
		    startValue: 0,
		    endValue: 21,
		    unit:"%",
		    property: 'translateY',
		  },
		  {
		    startValue: 2,
	          endValue: 2.8,
	          property: 'scale',
		  },
		 
		],
	},
	

]



const title4Data = [
	{
		start: '.trigger-image-1',
		duration: '20vh',
		startOffset: '200vh',
		properties: [
		  {
		    startValue: 0,
		    endValue: 1,
		    property: 'opacity',
		  },
		  {
		    startValue: 100,
		    endValue: 0,
		    unit:"%",
		    property: 'translateY',
		  },
		],
	}
	,
	{
		start: '.trigger-image-1',
		startOffset: '250vh',
		duration: '30vh',
		properties: [
		  
		  {
		    startValue: 1,
		    endValue: 0,
		    property: 'opacity',
		  },
		  {
		    startValue: 0,
		    endValue: -100,
		    unit:"%",
		    property: 'translateY',
		  },
		],
	},
]



const content5DataTitle = [
	{
		start: '.trigger-image-1',
		duration: '20vh',
		startOffset: '270vh',
		properties: [
		  {
		    startValue: 0,
		    endValue: 1,
		    property: 'opacity',
		  },
		],
	}
	,
	{
		start: '.trigger-image-1',
		startOffset: '320vh',
		duration: '30vh',
		properties: [
		  
		  {
		    startValue: 1,
		    endValue: 0,
		    property: 'opacity',
		  },
		  {
		    startValue: 0,
		    endValue: -100,
		    unit:"%",
		    property: 'translateY',
		  },
		],
	},

]

const content6DataTitle = [
	{
		start: '.trigger-image-1',
		duration: '20vh',
		startOffset: '330vh',
		properties: [
		  {
		    startValue: 0,
		    endValue: 1,
		    property: 'opacity',
		  },
		],
	}
	,
	{
		start: '.trigger-image-1',
		startOffset: '380vh',
		duration: '30vh',
		properties: [
		  
		  {
		    startValue: 1,
		    endValue: 0,
		    property: 'opacity',
		  },
		  {
		    startValue: 0,
		    endValue: -100,
		    unit:"%",
		    property: 'translateY',
		  },
		],
	},

]

const content7DataTitle = [
	{
		start: '.trigger-image-1',
		duration: '20vh',
		startOffset: '390vh',
		properties: [
		  {
		    startValue: 0,
		    endValue: 1,
		    property: 'opacity',
		  },
		],
	}
	,
	{
		start: '.trigger-image-1',
		startOffset: '440vh',
		duration: '30vh',
		properties: [
		  
		  {
		    startValue: 1,
		    endValue: 0,
		    property: 'opacity',
		  },
		  {
		    startValue: 0,
		    endValue: -100,
		    unit:"%",
		    property: 'translateY',
		  },
		],
	},

]

const content5Data = [
	{
		start: '.trigger-image-1',
		duration: '20vh',
		startOffset: '280vh',
		properties: [
		  {
		    startValue: 0,
		    endValue: 1,
		    property: 'opacity',
		  },
		  {
		    startValue: -100,
		    endValue: 0,
		    unit:"%",
		    property: 'translateX',
		  },
		],
	}
	,
	{
		start: '.trigger-image-1',
		startOffset: '450vh',
		duration: '30vh',
		properties: [
		  
		  {
		    startValue: 1,
		    endValue: 0,
		    property: 'opacity',
		  },
		  {
		    startValue: 0,
		    endValue: 100,
		    unit:"%",
		    property: 'translateX',
		  },
		],
	},
]

const content6Data = [
	{
		start: '.trigger-image-1',
		duration: '20vh',
		startOffset: '340vh',
		properties: [
		  {
		    startValue: 0,
		    endValue: 1,
		    property: 'opacity',
		  },
		  {
		    startValue: -100,
		    endValue: 0,
		    unit:"%",
		    property: 'translateX',
		  },
		],
	}
	,
	{
		start: '.trigger-image-1',
		startOffset: '450vh',
		duration: '30vh',
		properties: [
		  
		  {
		    startValue: 1,
		    endValue: 0,
		    property: 'opacity',
		  },
		  {
		    startValue: 0,
		    endValue: 100,
		    unit:"%",
		    property: 'translateX',
		  },
		],
	},
]


const content7Data = [
	{
		start: '.trigger-image-1',
		duration: '20vh',
		startOffset: '400vh',
		properties: [
		  {
		    startValue: 0,
		    endValue: 1,
		    property: 'opacity',
		  },
		  {
		    startValue: 100,
		    endValue: 0,
		    unit:"%",
		    property: 'translateX',
		  },
		],
	}
	,
	{
		start: '.trigger-image-1',
		startOffset: '450vh',
		duration: '30vh',
		properties: [
		  
		  {
		    startValue: 1,
		    endValue: 0,
		    property: 'opacity',
		  },
		  {
		    startValue: 0,
		    endValue: -100,
		    unit:"%",
		    property: 'translateX',
		  },
		],
	},
]

const content8Data = [
	{
		start: '.trigger-image-1',
		duration: '30vh',
		startOffset: '470vh',
		properties: [
		  {
		    startValue: 0,
		    endValue: 1,
		    property: 'opacity',
		  }
		],
	}
	
]


const contentImageBottom1Data = [
	{
		start: 'self',
		startOffset: 200,
    	duration: 150,
		properties: [
		  {
		    startValue: 0,
		    endValue: 1,
		    property: 'opacity',
		  },
		  ,
		  {
	        startValue: 0,
	        endValue: -80,
	        unit: 'px',
	        property: 'translateY',
	      },
		],
	}
	
]

export default function Feature2DetailsView(props) {
	//const artwork = props.artwork
	const [hasLoaded, setHasLoaded] = useState(false)
	const fullHeight = window.innerHeight
	const [imageURL, setImageURL] = useState("");

	const navigate = useNavigate()
	
	const goToContent = () => {	
   		animateScroll(500, { minDuration: 1500 });
  	};

  	const displayImage = (imgURL) => {
  		setImageURL(imgURL)
  	}

  	const onClickDone = () => {
  		setImageURL("")
  	}

  	useEffect(() => {
    	window.scrollTo(0, 0);

    	const images = []
	    images.push("/img/patterns.png");
	    images.push("/img/stop4/parallax04-01-zoom1242x1394.jpg");
	    images.push("/img/stop4/closeup01-1x.jpg");
	    images.push("/img/stop4/closeup02-1x.jpg");
	    images.push("/img/stop4/closeup03-1x.jpg");
	    images.push("/img/stop4/parallax04-02-1x.jpg");
	    images.push("/img/stop4/parallax04-03-1x.jpg");
	    images.push("/img/stop4/parallax04-04-1x.jpg");
	    images.push("/img/stop4/parallax04-05-1x.jpg");
	    images.push("/img/stop4/parallax04-06-1x.jpg");
	    images.push("/img/stop4/parallax04-07-1x.jpg");
	    images.push("/img/stop4/parallax04-08-1x.jpg");
	    images.push("/img/stop4/parallax04-01-zoom01-1x.jpg");


	    let index=0
        images.forEach((image) => {
        new Image().src = image
        index ++;
        if (index === images.length) {
          
   
          setTimeout(() => {
              setHasLoaded(true)
            }, 500);

        }
      });


	});


//<div className="feature2Bg" style={{backgroundImage: `url(/img/patterns.png)`}} />

/*
<h3>From a tuft of hair</h3>
<h3>to ruddy cheeks</h3>
<h3>to a piercing sidelong glance</h3>
*/
	if(!hasLoaded) {
		return (
			<Div100vh dontresize="true" className="container">
				<Header hasBack={true} isFromApp={props.isFromApp} />
				<div  className="feature2Container" style={{backgroundColor: props.artwork.color}} >
					
					<div className="loader-container">
		              <img src="/loader.gif"  alt="loader icon"/>
		            </div>

				</div>

			</Div100vh>
			)
	}
	return (
		<DeviceOrientation lockOrientation={'portrait'} className="parallaxContainer feature4DetailsView ">
        	<Orientation orientation='portrait' alwaysRender={false}>
		        
		        <div className="parallaxContainer-inner " style={ imageURL !== "" ? { overflow:'hidden', pointerEvents: "none" , backgroundColor: props.artwork.color} : {backgroundColor: props.artwork.color}}>
		        	<img style={{backgroundColor: props.artwork.color}} className="feature2Bg fadeIn" src="/img/patterns.png" />
			        
			        <Div100vh  dontresize="true" className="container">
			        	<Header hasBack={true} isFromApp={props.isFromApp} />
			       
			       		<div className="feature2Container fadeIn" >
					       
					        <Plx tagName='h1' parallaxData={ titleData } >
					            A rare  collection of Lavinia Fontana's drawings awaits…
					        </Plx>
							
							

					        <Plx parallaxData={ scrollDownData } className="scrollDown" onClick={goToContent}>
					            

				                <div >
					                <p>Slowly scroll down to explore</p>
					     
					                <div className="arrow-container animated fadeInDown"   >
							          <div className="arrow-2">
							            <img src="/scroll-icon.png" alt="scroll icon" />
							          </div>
							          <div className="arrow-1 animated hinge infinite zoomInScroll"></div>
							        </div>


				                </div>


				            </Plx>
				        </div>

			        </Div100vh>

			        <div id="contentFeature"  >
			        	<div className="trigger-image-1" />
			        	<Plx tagName='h3' parallaxData={ title1Data } className='text-1-parallax'>
							Fontana spent <span>time</span> with these people.
						</Plx>
						<Plx tagName='h3' parallaxData={ title2Data } className='text-1-parallax'>
							Closely <span>observing</span> their features. 
						</Plx>
						<Plx tagName='h3' parallaxData={ title3Data } className='text-1-parallax'>
							Perhaps for <span>hours</span> at a time.
						</Plx>

						<Plx tagName='h3' parallaxData={ content5DataTitle } className='text-1-parallax'>
							From a tuft of hair
						</Plx>
						<Plx tagName='h3' parallaxData={ content6DataTitle } className='text-1-parallax'>
							to ruddy cheeks
						</Plx>
						<Plx tagName='h3' parallaxData={ content7DataTitle } className='text-1-parallax'>
							to a piercing sidelong glance
						</Plx>

						
						<Plx parallaxData={ scrollDownData2 } className="scrollDown" >
					            <div >
					                <p>Keep scrolling gradually to continue</p>
					                <img src="/scroll-icon.png" alt="scroll icon" />
				                </div>
				            </Plx>
			        	

			        	<Plx parallaxData={ image1Data } className='img-1-parallax'>
			        		<div className="img-content">
			        			<img src="/img/stop4/parallax04-01-zoom1242x1394.jpg"  alt="img-1-parallax" />

			        			<Plx className='img-content-abs2' parallaxData={ image1ZommedData } >
			        				<img src="/img/stop4/parallax04-01-zoom1242x1394.jpg"  alt="img-1-parallax" />
			        			</Plx>

			        		</div>
			        	</Plx>



			        	
			        	<Plx tagName='h3' parallaxData={ title4Data } className='img-1-parallax text-centered-parallax'>
							<div>Her drawings reflect that intimacy.<br/><br/>Though sketch-like,<br/>they seem to capture some essence of the sitter's personality</div> 
						</Plx>

			        	<Plx parallaxData={ content5Data } className='img-1-parallax content5Data'>
			        		<div>
				        		<img src="/img/stop4/closeup01-1x.jpg"  alt="img-1-parallax" />
			        		</div>
			        	</Plx>
			        	<Plx parallaxData={ content6Data } className='img-1-parallax content6Data'>
			        		<div>
				        		
				        		<img src="/img/stop4/closeup02-1x.jpg"  alt="img-1-parallax" />
			        		</div>
			        	</Plx>
			        	<Plx parallaxData={ content7Data } className='img-1-parallax content7Data'>
			        		<div>
				        		
				        		<img src="/img/stop4/closeup03-1x.jpg"  alt="img-1-parallax" />
			        		</div>
			        	</Plx>

			        </div>

			        <Plx parallaxData={ content8Data } className='final-content-parallax'>
			        		<div>
				        		<h3>Continue scrolling to find your favorite drawing among this collection.<br/><br/>When you do, tap to zoom in, and discover which details stand out to you.</h3>
				        		
				        		<Plx parallaxData={ contentImageBottom1Data } className="imageBottom">
					        		<div className="final-content-img-container" onClick={function() {window.open("/img/stop4/parallax04-02-3x.jpg", "_blank")}}><img src="/img/stop4/parallax04-02-1x.jpg"  alt="img-1-parallax" /><img src="/icon-increase-image-seize.png" className="zoomInImageIcon" alt="zoom icon"/></div>
					        		<h4 className="italic">Portrait of a Friar</h4>
				        		</Plx>
				        		<Plx parallaxData={ contentImageBottom1Data } className="imageBottom">
					        		<div className="final-content-img-container" onClick={function() {window.open("/img/stop4/parallax04-03-3x.jpg", "_blank")}}><img src="/img/stop4/parallax04-03-1x.jpg"  alt="img-1-parallax" /><img src="/icon-increase-image-seize.png" className="zoomInImageIcon" alt="zoom icon"/></div>
					        		<h4 className="italic">Nun or Young Woman with a Veil</h4>
					        	</Plx>
				        		<Plx parallaxData={ contentImageBottom1Data } className="imageBottom">
					        		<div className="final-content-img-container" onClick={function() {window.open("/img/stop4/parallax04-04-3x.jpg", "_blank")}}><img src="/img/stop4/parallax04-04-1x.jpg"  alt="img-1-parallax" /><img src="/icon-increase-image-seize.png" className="zoomInImageIcon" alt="zoom icon"/></div>
					        		<h4 className="italic">Portrait of a Lady</h4>
					        	</Plx>
				        		<Plx parallaxData={ contentImageBottom1Data } className="imageBottom">
					        		<div className="final-content-img-container" onClick={function() {window.open("/img/stop4/parallax04-05-3x.jpg", "_blank")}}><img src="/img/stop4/parallax04-05-1x.jpg"  alt="img-1-parallax" /><img src="/icon-increase-image-seize.png" className="zoomInImageIcon" alt="zoom icon"/></div>
					        		<h4 className="italic">Portrait of a Bearded Old Man</h4>
					        	</Plx>
				        		<Plx parallaxData={ contentImageBottom1Data } className="imageBottom">
					        		<div className="final-content-img-container" onClick={function() {window.open("/img/stop4/parallax04-06-3x.jpg", "_blank")}}><img src="/img/stop4/parallax04-06-1x.jpg"  alt="img-1-parallax" /><img src="/icon-increase-image-seize.png" className="zoomInImageIcon" alt="zoom icon"/></div>
					        		<h4 className="italic">Portrait of a Young Man with a Moustache and Beard</h4>
					        	</Plx>
				        		<Plx parallaxData={ contentImageBottom1Data } className="imageBottom">	
					        		<div className="final-content-img-container" onClick={function() {window.open("/img/stop4/parallax04-07-3x.jpg", "_blank")}}><img src="/img/stop4/parallax04-07-1x.jpg"  alt="img-1-parallax" /><img src="/icon-increase-image-seize.png" className="zoomInImageIcon" alt="zoom icon"/></div>
					        		<h4 className="italic">Portrait of the Daughter of Pedro Gonzalez</h4>
					        	</Plx>
				        		<Plx parallaxData={ contentImageBottom1Data } className="imageBottom">
					        		<div className="final-content-img-container" onClick={function() {window.open("/img/stop4/parallax04-08-3x.jpg", "_blank")}}><img src="/img/stop4/parallax04-08-1x.jpg"  alt="img-1-parallax" /><img src="/icon-increase-image-seize.png" className="zoomInImageIcon" alt="zoom icon"/></div>
					        		<h4 className="italic">Portrait of a Lady</h4>
					        	</Plx>
				        		<Plx parallaxData={ contentImageBottom1Data } className="imageBottom">
					        		<div className="final-content-img-container" onClick={function() {window.open("/img/stop4/parallax04-01-zoom1242x1394.jpg")}}><img src="/img/stop4/parallax04-01-zoom01-1x.jpg"  alt="img-1-parallax" /><img src="/icon-increase-image-seize.png" className="zoomInImageIcon" alt="zoom icon"/></div>
				        			<h4 className="italic">Self-Portrait</h4>
				        		</Plx>
			        			<h5>Images courtesy of<br/>The Morgan Library & Museum</h5>

			        			<a href="/" onClick={function(e){e.preventDefault(); if(props.isFromApp){navigate(-1);}else {window.location.href = "/"}}}>BACK TO PLAYLIST</a>
			        		</div>
			        	</Plx>
			    </div>
		        

		        {(imageURL !== "")  &&
		          (<Div100vh className="overlayFullScreen" >
		          	  <Header hasDone={true} clickDone = {onClickDone} />
		              <div onClick={function(e){if(e.target.nodeName !== "IMG") {setImageURL("")}}}>
		              	<img src={imageURL} alt="img parallax"/>
		              </div>
		          </Div100vh>)
		          }

        	</Orientation>


        	<Orientation orientation='landscape'  alwaysRender={false}>
	          <Div100vh  dontresize="true" className="landscapeDiv">
	            <p>For the optimal tour experience,<br/> please turn your phone to portrait mode.</p>
	          </Div100vh>
	        </Orientation>


        </DeviceOrientation>

	)


}

