import React from 'react';

export default function Footer(props) {




 

  

  return (
    <footer>
          <p><a href="https://www.dia.org/privacypolicy">Privacy Policy</a> | <a href="https://www.dia.org/privacypolicy">Terms of Use</a><br/><br/>©2022 Detroit Institute of Arts.<br/>All Rights Reserved.</p>
    </footer>
    );
    



}



