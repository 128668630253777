import React from 'react';
import projects from '../datas/Projects.js';
import Header from "../components/Header";
import DeviceOrientation, { Orientation } from 'react-screen-orientation'
import Div100vh from 'react-div-100vh';
import Plx from 'react-plx';

export default function ProjectDetailsView(props) {
	const project = projects[props.match.params.id];

	const scrollDownData= [
	  {
	    start: 'self',
	    startOffset: 100,
	    duration: 200,
	    properties: [
	    {
	          startValue: 1,
	          endValue: 0,
	          property: 'opacity',
	    }
	    ],
	  },
	];


	const goToContent = () => {	
   		document.getElementById('contentFeature').scrollIntoView({behavior: 'smooth'})
  	};

	
	return (

		<DeviceOrientation lockOrientation={'portrait'} className="projectView">
	        <Orientation orientation='portrait' alwaysRender={false}>
				<div>
					<Header hasBack={true} />
					<h1>{project.title}</h1>
					 <div className="text-content-details"   dangerouslySetInnerHTML={{ __html: project.description }}></div>
				</div>

				 <Plx parallaxData={ scrollDownData } className="scrollDown">
		            <div onClick={goToContent}>
		                <img src="/scroll-icon.png" alt="scroll icon" />
	                </div>
	            </Plx>
			</Orientation>


			<Orientation orientation='landscape'  alwaysRender={false}>
	          <Div100vh  dontresize="true" className="landscapeDiv">
	            <p>For the optimal tour experience,<br/> please turn your phone to portrait mode.</p>
	          </Div100vh>
	        </Orientation>


      	</DeviceOrientation>


	)
  


}



