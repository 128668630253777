import React from 'react';
import { useNavigate } from "react-router-dom";

import ReactGA from 'react-ga';

export default function Header(props) {

  	const navigate = useNavigate()

	const goBack = (e) => {
	  navigate(-1)
	  ReactGA.pageview("/");
	}

	return (
	<header>
	      {props.hasBack !== undefined && (
<>

	      {	props.isFromApp === true ? (
	      		<a href="/" onClick={function(e){e.preventDefault(); goBack();}} className="backButton">BACK</a>
	      	) : (
	      		<a href="/"  className="backButton">&lt; PLAYLIST</a>
	      ) }
	      	


</>

	      	)}
	      {props.hasDone !== undefined && (<a href="/" onClick={function(e){e.preventDefault(); props.clickDone();}} className="backButton">DONE</a>)}
	      {props.hasClose !== undefined && (<a href="/" onClick={function(e){e.preventDefault(); props.clickClose();}} className="backButton">CLOSE</a>)}
	      <a href="/"><img src="/logo-grey.svg" alt="logo"/></a>
	      {props.hasSave !== undefined && (<a href="/" onClick={function(e){e.preventDefault(); props.clickSave();}} className="rightButton">SUBMIT</a>)}
	      
	      
	</header>
	);
    



}



