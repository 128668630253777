import React, { useState, useEffect } from "react";
import Div100vh from 'react-div-100vh';
import { use100vh } from 'react-div-100vh'


import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
import { useInterval } from '../helper/functions.js';

import Column1 from "../components/Visualisation/Column1"
import Column2 from "../components/Visualisation/Column2"
import Column3 from "../components/Visualisation/Column3"
import Column4 from "../components/Visualisation/Column4"

export default function Visualisation(props) {


  const height = use100vh() - 50
 

  useInterval(() => {
    window.location.reload(true);
  }, 120*60000);


  
//console.log("render visu")



  return (
    <div className="visualisationContainer visualisationContainer2">
      <Column1 height = {height} />
      <Column2 height = {height} />
      <Column3 height = {height} />
      <Column4 />

    	
  	</div>
    );
    


}


