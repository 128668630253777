import React, { useState, useEffect, useRef } from "react";
import Div100vh from 'react-div-100vh';
import { use100vh } from 'react-div-100vh'

import ReactWordcloud from 'react-wordcloud';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';

  
export default function Visualisation2(props) {


  const [prompt4Data, setPrompt4Data] = useState([]);
  const [prompt1Data, setPrompt1Data] = useState(null);
  const [prompt2Data, setPrompt2Data] = useState(null);
  const [prompt3Data, setPrompt3Data] = useState(null);
  const [textPromptIndex, setTextPromptIndex] = useState(0);
  const [prompt4Cloud, setPrompt4Cloud] = useState([]);
  const [latestPrompt4, setLatestPrompt4] = useState("")
  const [hasNewPrompt4, setHasNewPrompt4]= useState(false);
  const height = use100vh() - 50
  const textPrompt = ["Where would you be?", "What would you be doing?", "What are you holding?", "How are you posed?"];

  //console.log(prompt4Data)
  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest function.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }




  const getData = () => {
    


    fetch("/getPrompt0")
      .then((res) => res.json())
      .then((data) => {
        //console.log("prompt0")
        //console.log(data)
        var hasChanged = false
        Object.keys(data).forEach(key => {

            

            if(prompt1Data !== null) {
              if(prompt1Data[key].percent !== data[key].percent) {
                hasChanged = true;
              }
              
            }
            if(prompt1Data === null) {
              hasChanged = true
            }

           

            var w = ((height * data[key].percent)/100) * 0.9

            if(w < 90) {
              data[key].width = 90;
            }else if(w < 120) {
              data[key].width = 120;
            }else if(w > 450) {
              data[key].width = 450;
              
            }else {
              data[key].width = w;

            }
            
            if(data[key].marginTop === 0) {
              data[key].marginTop = Math.floor(Math.random() * 20) - (20 +Math.floor(Math.random() * 30)) +'px';
            }
            
            //data[key].marginLeft = (data[key].percent < 30) ?  -Math.floor(Math.random() * 100) +'px' : -Math.floor(Math.random() * 50) +'px';
            
            
            if(data[key].percent < 20) {
              data[key].fontFamily = 18;
              data[key]["boxShadowMultiplier"]= 0.4
            }else if(data[key].percent < 24) {
              data[key].fontFamily = 20;
              data[key]["boxShadowMultiplier"]= 0.6
            }else if(data[key].percent < 49) {
              data[key].fontFamily = 26;
              data[key]["boxShadowMultiplier"]= 0.8
            }else if(data[key].percent < 74) {
              data[key].fontFamily = 30;
              data[key]["boxShadowMultiplier"]= 1
            } 

            

        });



        if(hasChanged) {
          setPrompt1Data(data)
        }



        
      });


    fetch("/getPrompt1")
      .then((res) => res.json())
      .then((data) => {
        //console.log("prompt0")
        //console.log(data)
        


        var hasChanged = false
        Object.keys(data).forEach(key => {

            var w = ((height * data[key].percent)/100) - 10

            if(prompt2Data !== null) {
              if(prompt2Data[key].percent !== data[key].percent) {
                hasChanged = true;
              }
              
            }
            if(prompt1Data === null) {
              hasChanged = true
            }

            if(w < 80) {
              data[key].width = 100;
            }else if(w > 350) {
              data[key].width = 350;
            }else {
              data[key].width = w;
            }
            
            if(data[key].marginTop === 0) {
              data[key].marginTop = Math.floor(Math.random() * 20) - (10 +Math.floor(Math.random() * 20)) +'px';
            }
            //data[key].marginLeft = (data[key].percent < 30) ?  -Math.floor(Math.random() * 100) +'px' : -Math.floor(Math.random() * 50) +'px';

            if(data[key].percent < 20) {
              data[key].fontFamily = 18;
            }else  if(data[key].percent < 24) {
              data[key].fontFamily = 20;
            }else if(data[key].percent < 49) {
              data[key].fontFamily = 26;
            }else if(data[key].percent < 74) {
              data[key].fontFamily = 30;
            } 

            
           

        });

        if(hasChanged) {
          setPrompt2Data(data)
        }


      });


    fetch("/getPrompt2")
      .then((res) => res.json())
      .then((data) => {
        //console.log("prompt0")
        //console.log(data)
        


        var hasChanged = false
        Object.keys(data).forEach(key => {

            var w = (((height+50) * data[key].percent)/100)
            if(prompt3Data !== null) {
              if(prompt3Data[key].percent !== data[key].percent) {
                hasChanged = true;
              }
              
            }
            if(prompt1Data === null) {
              hasChanged = true
            }


            data[key].width = w;

            if(data[key].percent < 20) {
              data[key].fontFamily = 18;
            }else  if(data[key].percent < 24) {
              data[key].fontFamily = 20;
            }else if(data[key].percent < 49) {
              data[key].fontFamily = 26;
            }else if(data[key].percent < 74) {
              data[key].fontFamily = 30;
            } 

           ////animation-delay: 0.7s;

        });

        if(hasChanged) {
          setPrompt3Data(data)
        }
      });


    fetch("/getPrompt3")
      .then((res) => res.json())
      .then((data) => {
        if(data.length > 0){

          
          if(data.length !== prompt4Data.length) {

           
            //Nouvelle entry
            let text = data[data.length-1].text;
            if (text.length > 200) {
              text = data[data.length-1].text.substring(0, 230) + '...'
            }
            setLatestPrompt4(text)
            setHasNewPrompt4(true)

            


            var newArray  = data.map(object => ({ ...object }));
            
            newArray.splice(-1);
            
            for (var i = 0; i < newArray.length; i++) {
                let t = newArray[i].text
                if ( t.length > 20) {
                  newArray[i].text =  t.substring(0, 20) + '...'
                }
            }

          

            setPrompt4Cloud(newArray)

            var data4 = data.map(object => ({ ...object }));
            setPrompt4Data(data4);

          }







        }
        

      });
  } 

  const updatePrompt4 = () => {
    //console.log("updatePrompt4"+hasNewPrompt4)
    if(!hasNewPrompt4) {
      const randomElement = Math.floor(Math.random() * prompt4Data.length);
      console.log(randomElement)
      

      let text = prompt4Data[randomElement].text;
      if (text.length > 200) {
          text = prompt4Data[randomElement].text.substring(0, 230) + '...'
      }

      var newArray = prompt4Data.map(object => ({ ...object }));
      newArray.splice(randomElement, 1);

      for (var i = 0; i < newArray.length; i++) {
          let t = newArray[i].text
          if ( t.length > 20) {
            newArray[i].text =  t.substring(0, 20) + '...'
          }
      }
      //console.log(newArray)

      setLatestPrompt4(text)
      setPrompt4Cloud(newArray)
    }else {
      setHasNewPrompt4(false)
    }
    
  }


  useEffect(() => {

    getData();



  }, [height]);

  useInterval(() => {
    getData();
  }, 30000);

  useInterval(() => {
    updatePrompt4();
  }, 13000);

  useInterval(() => {
    window.location.reload(true);
  }, 120*60000);


  useInterval(() => {
    //console.log("rotate prompt")
    if(textPromptIndex < textPrompt.length-1){
      setTextPromptIndex(textPromptIndex+1)
    }else {
      setTextPromptIndex(0)
    }
        
  }, 3000);


  const options = {
    rotations: 1,
    rotationAngles: [0],
    fontSizes: [19,19],
    fontFamily:"sans-serif"
  };

  const reactWordcloudCallbacks = {
    getWordColor: word => "#fff",
  }



  return (
    <div className="visualisationContainer visualisationContainer2">
      <div className="column column-1">
        {(prompt1Data != null)  &&
         (<>
            <div className="pulse-box">
              <div className="loader" style={{ width: (prompt1Data.unsure.width)+'px', height: (prompt1Data.unsure.width)+'px', marginTop:'20px'}}>
                <span className="loader__inner" style={{boxShadow: `0 ${(prompt1Data.unsure.boxShadowMultiplier*-20)+'px'} 0 0 rgba(255, 255, 255, 0.1), ${(prompt1Data.unsure.boxShadowMultiplier*-15)+'px'} ${(prompt1Data.unsure.boxShadowMultiplier*10)+'px'} 0 0 rgba(255, 255, 255, 0.1), ${(prompt1Data.unsure.boxShadowMultiplier*15)+'px'} ${(prompt1Data.unsure.boxShadowMultiplier*10)+'px'} 0 0 rgba(255, 255, 255, 0.2)`,animationDelay:(prompt1Data.unsure.animationDelay)+"s", width: (prompt1Data.unsure.width)+'px', height: (prompt1Data.unsure.width)+'px'}}></span>
                <span className="loader__inner" style={{boxShadow: `${(prompt1Data.unsure.boxShadowMultiplier*15)+'px'} ${(prompt1Data.unsure.boxShadowMultiplier*-10)+'px'} 0 0 rgba(255, 255, 255, 0.25),${(prompt1Data.unsure.boxShadowMultiplier*-15)+'px'} ${(prompt1Data.unsure.boxShadowMultiplier*-10)+'px'} 0 0 rgba(255, 255, 255, 0.1), 0 ${(prompt1Data.unsure.boxShadowMultiplier*20)+'px'} 0 0 rgba(255, 255, 255, 0.3)`,animationDelay:(prompt1Data.unsure.animationDelay)+"s", width: (prompt1Data.unsure.width)+'px', height: (prompt1Data.unsure.width)+'px'}}></span>
                <p style={{fontSize: prompt1Data.unsure.fontFamily+'px', lineHeight: (prompt1Data.unsure.fontFamily*0.8)+'px' }}>UNSURE<br/><span style={{fontSize: (prompt1Data.unsure.fontFamily*0.75)+'px'}} >{Math.floor(prompt1Data.unsure.percent)}%</span></p>
          
              </div>

            </div>

            <div className="pulse-box">
              <div className="loader" style={{width: (prompt1Data.optimistic.width)+'px', height: (prompt1Data.optimistic.width)+'px', marginTop: prompt1Data.optimistic.marginTop, marginLeft: prompt1Data.optimistic.marginLeft }}>
                <span className="loader__inner" style={{boxShadow: `0 ${(prompt1Data.optimistic.boxShadowMultiplier*-20)+'px'} 0 0 rgba(255, 255, 255, 0.1), ${(prompt1Data.optimistic.boxShadowMultiplier*-15)+'px'} ${(prompt1Data.optimistic.boxShadowMultiplier*10)+'px'} 0 0 rgba(255, 255, 255, 0.1), ${(prompt1Data.optimistic.boxShadowMultiplier*15)+'px'} ${(prompt1Data.optimistic.boxShadowMultiplier*10)+'px'} 0 0 rgba(255, 255, 255, 0.2)`,animationDelay:(prompt1Data.optimistic.animationDelay)+"s", width: (prompt1Data.optimistic.width)+'px', height: (prompt1Data.optimistic.width)+'px'}}></span>
                <span className="loader__inner" style={{boxShadow: `${(prompt1Data.optimistic.boxShadowMultiplier*15)+'px'} ${(prompt1Data.optimistic.boxShadowMultiplier*-10)+'px'} 0 0 rgba(255, 255, 255, 0.25),${(prompt1Data.optimistic.boxShadowMultiplier*-15)+'px'} ${(prompt1Data.optimistic.boxShadowMultiplier*-10)+'px'} 0 0 rgba(255, 255, 255, 0.1), 0 ${(prompt1Data.optimistic.boxShadowMultiplier*20)+'px'} 0 0 rgba(255, 255, 255, 0.3)`,animationDelay:(prompt1Data.optimistic.animationDelay)+"s", width: (prompt1Data.optimistic.width)+'px', height: (prompt1Data.optimistic.width)+'px'}}></span>
                <p style={{fontSize: prompt1Data.optimistic.fontFamily+'px', lineHeight: (prompt1Data.optimistic.fontFamily*0.8)+'px' }}>OPTIMISTIC<br/><span style={{fontSize: (prompt1Data.optimistic.fontFamily*0.75)+'px'}} >{Math.floor(prompt1Data.optimistic.percent)}%</span></p>
          
              </div>
            </div>

            <div className="pulse-box">
              <div className="loader" style={{width: (prompt1Data.overwhelmed.width)+'px', height: (prompt1Data.overwhelmed.width)+'px', marginTop: prompt1Data.overwhelmed.marginTop, marginLeft: prompt1Data.overwhelmed.marginLeft }}>
                <span className="loader__inner" style={{boxShadow: `0 ${(prompt1Data.overwhelmed.boxShadowMultiplier*-20)+'px'} 0 0 rgba(255, 255, 255, 0.1), ${(prompt1Data.overwhelmed.boxShadowMultiplier*-15)+'px'} ${(prompt1Data.overwhelmed.boxShadowMultiplier*10)+'px'} 0 0 rgba(255, 255, 255, 0.1), ${(prompt1Data.overwhelmed.boxShadowMultiplier*15)+'px'} ${(prompt1Data.overwhelmed.boxShadowMultiplier*10)+'px'} 0 0 rgba(255, 255, 255, 0.2)`,animationDelay:(prompt1Data.overwhelmed.animationDelay)+"s", width: (prompt1Data.overwhelmed.width)+'px', height: (prompt1Data.overwhelmed.width)+'px'}}></span>
                <span className="loader__inner" style={{boxShadow: `${(prompt1Data.overwhelmed.boxShadowMultiplier*15)+'px'} ${(prompt1Data.overwhelmed.boxShadowMultiplier*-10)+'px'} 0 0 rgba(255, 255, 255, 0.25),${(prompt1Data.overwhelmed.boxShadowMultiplier*-15)+'px'} ${(prompt1Data.overwhelmed.boxShadowMultiplier*-10)+'px'} 0 0 rgba(255, 255, 255, 0.1), 0 ${(prompt1Data.overwhelmed.boxShadowMultiplier*20)+'px'} 0 0 rgba(255, 255, 255, 0.3)`,animationDelay:(prompt1Data.overwhelmed.animationDelay)+"s", width: (prompt1Data.overwhelmed.width)+'px', height: (prompt1Data.overwhelmed.width)+'px'}}></span>
                <p style={{fontSize: prompt1Data.overwhelmed.fontFamily+'px', lineHeight: (prompt1Data.overwhelmed.fontFamily*0.8)+'px' }}>OVERWHELMED<br/><span style={{fontSize: (prompt1Data.overwhelmed.fontFamily*0.75)+'px'}} >{Math.floor(prompt1Data.overwhelmed.percent)}%</span></p>
          
              </div>
            </div>

            <div className="pulse-box">
              <div className="loader" style={{width: (prompt1Data.curious.width)+'px', height: (prompt1Data.curious.width)+'px', marginTop: prompt1Data.curious.marginTop, marginLeft: prompt1Data.curious.marginLeft }}>
                <span className="loader__inner" style={{boxShadow: `0 ${(prompt1Data.curious.boxShadowMultiplier*-20)+'px'} 0 0 rgba(255, 255, 255, 0.1), ${(prompt1Data.curious.boxShadowMultiplier*-15)+'px'} ${(prompt1Data.curious.boxShadowMultiplier*10)+'px'} 0 0 rgba(255, 255, 255, 0.1), ${(prompt1Data.curious.boxShadowMultiplier*15)+'px'} ${(prompt1Data.curious.boxShadowMultiplier*10)+'px'} 0 0 rgba(255, 255, 255, 0.2)`,animationDelay:(prompt1Data.curious.animationDelay)+"s", width: (prompt1Data.curious.width)+'px', height: (prompt1Data.curious.width)+'px'}}></span>
                <span className="loader__inner" style={{boxShadow: `${(prompt1Data.curious.boxShadowMultiplier*15)+'px'} ${(prompt1Data.curious.boxShadowMultiplier*-10)+'px'} 0 0 rgba(255, 255, 255, 0.25),${(prompt1Data.curious.boxShadowMultiplier*-15)+'px'} ${(prompt1Data.curious.boxShadowMultiplier*-10)+'px'} 0 0 rgba(255, 255, 255, 0.1), 0 ${(prompt1Data.curious.boxShadowMultiplier*20)+'px'} 0 0 rgba(255, 255, 255, 0.3)`,animationDelay:(prompt1Data.curious.animationDelay)+"s", width: (prompt1Data.curious.width)+'px', height: (prompt1Data.curious.width)+'px'}}></span>
                <p style={{fontSize: prompt1Data.curious.fontFamily+'px', lineHeight: (prompt1Data.curious.fontFamily*0.8)+'px' }}>CURIOUS<br/><span style={{fontSize: (prompt1Data.curious.fontFamily*0.75)+'px'}} >{Math.floor(prompt1Data.curious.percent)}%</span></p>
          
              </div>
            </div>

            <div className="pulse-box">
              <div className="loader" style={{width: (prompt1Data.excited.width)+'px', height: (prompt1Data.excited.width)+'px', marginTop: prompt1Data.excited.marginTop, marginLeft: prompt1Data.excited.marginLeft }}>
                <span className="loader__inner" style={{boxShadow: `0 ${(prompt1Data.excited.boxShadowMultiplier*-20)+'px'} 0 0 rgba(255, 255, 255, 0.1), ${(prompt1Data.excited.boxShadowMultiplier*-15)+'px'} ${(prompt1Data.excited.boxShadowMultiplier*10)+'px'} 0 0 rgba(255, 255, 255, 0.1), ${(prompt1Data.excited.boxShadowMultiplier*15)+'px'} ${(prompt1Data.excited.boxShadowMultiplier*10)+'px'} 0 0 rgba(255, 255, 255, 0.2)`,animationDelay:(prompt1Data.excited.animationDelay)+"s", width: (prompt1Data.excited.width)+'px', height: (prompt1Data.excited.width)+'px'}}></span>
                <span className="loader__inner" style={{boxShadow: `${(prompt1Data.excited.boxShadowMultiplier*15)+'px'} ${(prompt1Data.excited.boxShadowMultiplier*-10)+'px'} 0 0 rgba(255, 255, 255, 0.25),${(prompt1Data.excited.boxShadowMultiplier*-15)+'px'} ${(prompt1Data.excited.boxShadowMultiplier*-10)+'px'} 0 0 rgba(255, 255, 255, 0.1), 0 ${(prompt1Data.excited.boxShadowMultiplier*20)+'px'} 0 0 rgba(255, 255, 255, 0.3)`,animationDelay:(prompt1Data.excited.animationDelay)+"s", width: (prompt1Data.excited.width)+'px', height: (prompt1Data.excited.width)+'px'}}></span>
                <p style={{fontSize: prompt1Data.excited.fontFamily+'px', lineHeight: (prompt1Data.excited.fontFamily*0.8)+'px' }}>EXCITED<br/><span style={{fontSize: (prompt1Data.excited.fontFamily*0.75)+'px'}} >{Math.floor(prompt1Data.excited.percent)}%</span></p>
          
              </div>
            </div>

            <div className="pulse-box">
              <div className="loader" style={{width: (prompt1Data.skeptical.width)+'px', height: (prompt1Data.skeptical.width)+'px', marginTop: prompt1Data.skeptical.marginTop, marginLeft: prompt1Data.skeptical.marginLeft }}>
                <span className="loader__inner" style={{boxShadow: `0 ${(prompt1Data.skeptical.boxShadowMultiplier*-20)+'px'} 0 0 rgba(255, 255, 255, 0.1), ${(prompt1Data.skeptical.boxShadowMultiplier*-15)+'px'} ${(prompt1Data.skeptical.boxShadowMultiplier*10)+'px'} 0 0 rgba(255, 255, 255, 0.1), ${(prompt1Data.skeptical.boxShadowMultiplier*15)+'px'} ${(prompt1Data.skeptical.boxShadowMultiplier*10)+'px'} 0 0 rgba(255, 255, 255, 0.2)`,animationDelay:(prompt1Data.skeptical.animationDelay)+"s", width: (prompt1Data.skeptical.width)+'px', height: (prompt1Data.skeptical.width)+'px'}}></span>
                <span className="loader__inner" style={{boxShadow: `${(prompt1Data.skeptical.boxShadowMultiplier*15)+'px'} ${(prompt1Data.skeptical.boxShadowMultiplier*-10)+'px'} 0 0 rgba(255, 255, 255, 0.25),${(prompt1Data.skeptical.boxShadowMultiplier*-15)+'px'} ${(prompt1Data.skeptical.boxShadowMultiplier*-10)+'px'} 0 0 rgba(255, 255, 255, 0.1), 0 ${(prompt1Data.skeptical.boxShadowMultiplier*20)+'px'} 0 0 rgba(255, 255, 255, 0.3)`,animationDelay:(prompt1Data.skeptical.animationDelay)+"s", width: (prompt1Data.skeptical.width)+'px', height: (prompt1Data.skeptical.width)+'px'}}></span>
                <p style={{fontSize: prompt1Data.skeptical.fontFamily+'px', lineHeight: (prompt1Data.skeptical.fontFamily*0.8)+'px' }}>SKEPTICAL<br/><span style={{fontSize: (prompt1Data.skeptical.fontFamily*0.75)+'px'}} >{Math.floor(prompt1Data.skeptical.percent)}%</span></p>
          
              </div>
            </div>

             <div className="pulse-box">
              <div className="loader" style={{width: (prompt1Data.somethingelse.width)+'px', height: (prompt1Data.somethingelse.width)+'px', marginTop: prompt1Data.somethingelse.marginTop, marginLeft: prompt1Data.somethingelse.marginLeft }}>
                <span className="loader__inner" style={{boxShadow: `0 ${(prompt1Data.somethingelse.boxShadowMultiplier*-20)+'px'} 0 0 rgba(255, 255, 255, 0.1), ${(prompt1Data.somethingelse.boxShadowMultiplier*-15)+'px'} ${(prompt1Data.somethingelse.boxShadowMultiplier*10)+'px'} 0 0 rgba(255, 255, 255, 0.1), ${(prompt1Data.somethingelse.boxShadowMultiplier*15)+'px'} ${(prompt1Data.somethingelse.boxShadowMultiplier*10)+'px'} 0 0 rgba(255, 255, 255, 0.2)`,animationDelay:(prompt1Data.somethingelse.animationDelay)+"s", width: (prompt1Data.somethingelse.width)+'px', height: (prompt1Data.somethingelse.width)+'px'}}></span>
                <span className="loader__inner" style={{boxShadow: `${(prompt1Data.somethingelse.boxShadowMultiplier*15)+'px'} ${(prompt1Data.somethingelse.boxShadowMultiplier*-10)+'px'} 0 0 rgba(255, 255, 255, 0.25),${(prompt1Data.somethingelse.boxShadowMultiplier*-15)+'px'} ${(prompt1Data.somethingelse.boxShadowMultiplier*-10)+'px'} 0 0 rgba(255, 255, 255, 0.1), 0 ${(prompt1Data.somethingelse.boxShadowMultiplier*20)+'px'} 0 0 rgba(255, 255, 255, 0.3)`,animationDelay:(prompt1Data.somethingelse.animationDelay)+"s", width: (prompt1Data.somethingelse.width)+'px', height: (prompt1Data.somethingelse.width)+'px'}}></span>
                <p style={{fontSize: prompt1Data.somethingelse.fontFamily+'px', lineHeight: (prompt1Data.somethingelse.fontFamily*0.8)+'px' }}>SOMETHING<br/>ELSE...<br/><span style={{fontSize: (prompt1Data.somethingelse.fontFamily*0.75)+'px'}} >{Math.floor(prompt1Data.somethingelse.percent)}%</span></p>
          
              </div>
            </div>

        </>
        )}

      </div>
      <div className="column column-2">
        {(prompt2Data != null)  &&
         (<>
         <div>
        <div className="request-loader" style={{width: (prompt2Data.somethingelse.width)+'px', height:(prompt2Data.somethingelse.width)+'px', marginTop:'20px', marginLeft: prompt2Data.somethingelse.marginLeft}}>
          <div className="request-loader-before" style={{animationDelay:(prompt2Data.somethingelse.animationDelay+0.6)+"s"}}  ></div>
          <p style={{fontSize: prompt2Data.somethingelse.fontFamily+'px', lineHeight: (prompt2Data.somethingelse.fontFamily*0.8)+'px'}}>SOMETHING<br/>ELSE...<br/><span style={{fontSize: (prompt2Data.somethingelse.fontFamily*0.75)+'px'}} >{Math.floor(prompt2Data.somethingelse.percent)}%</span></p>
          <div className="request-loader-after"  style={{animationDelay:(prompt2Data.somethingelse.animationDelay+0.5)+"s"}}  ></div>
          <div className="request-loader-after2"  style={{animationDelay:(prompt2Data.somethingelse.animationDelay+1.5)+"s"}}  ></div>
          <div className="request-loader-after3"  style={{animationDelay:(prompt2Data.somethingelse.animationDelay+2.5)+"s"}}  ></div>
        </div>
        </div>
        <div>
        <div className="request-loader" style={{width: (prompt2Data.overcomingbarriers.width)+'px', height:(prompt2Data.overcomingbarriers.width)+'px', marginTop:prompt2Data.overcomingbarriers.marginTop, marginLeft: prompt2Data.overcomingbarriers.marginLeft}}>
          <div className="request-loader-before"  style={{animationDelay:(prompt2Data.overcomingbarriers.animationDelay+0.6)+"s"}}  ></div>
          <p style={{fontSize: prompt2Data.overcomingbarriers.fontFamily+'px', lineHeight: (prompt2Data.overcomingbarriers.fontFamily*0.8)+'px'}}>OVERCOMING<br/>BARRIERS<br/><span style={{fontSize: (prompt2Data.overcomingbarriers.fontFamily*0.75)+'px'}} >{Math.floor(prompt2Data.overcomingbarriers.percent)}%</span></p>
          <div className="request-loader-after"  style={{animationDelay:(prompt2Data.overcomingbarriers.animationDelay+0.5)+"s"}}  ></div>
          <div className="request-loader-after2"  style={{animationDelay:(prompt2Data.overcomingbarriers.animationDelay+1.5)+"s"}}  ></div>
          <div className="request-loader-after3"  style={{animationDelay:(prompt2Data.overcomingbarriers.animationDelay+2.5)+"s"}}  ></div>
        </div>
        </div>
        <div>
        <div className="request-loader" style={{width: (prompt2Data.fame.width)+'px', height:(prompt2Data.fame.width)+'px', marginTop:prompt2Data.fame.marginTop, marginLeft: prompt2Data.fame.marginLeft}}>
          <div className="request-loader-before"  style={{animationDelay:(prompt2Data.fame.animationDelay+0.6)+"s"}}  ></div>
          <p style={{fontSize: prompt2Data.fame.fontFamily+'px', lineHeight: (prompt2Data.fame.fontFamily*0.8)+'px'}}>FAME<br/><span style={{fontSize: (prompt2Data.fame.fontFamily*0.75)+'px'}} >{Math.floor(prompt2Data.fame.percent)}%</span></p>
          <div className="request-loader-after"  style={{animationDelay:(prompt2Data.fame.animationDelay+0.5)+"s"}}  ></div>
          <div className="request-loader-after2"  style={{animationDelay:(prompt2Data.fame.animationDelay+1.5)+"s"}}  ></div>
          <div className="request-loader-after3"  style={{animationDelay:(prompt2Data.fame.animationDelay+2.5)+"s"}}  ></div>
        </div>
        </div>
        <div>
        <div className="request-loader" style={{width: (prompt2Data.businesssavy.width)+'px', height:(prompt2Data.businesssavy.width)+'px', marginTop:prompt2Data.businesssavy.marginTop, marginLeft: prompt2Data.businesssavy.marginLeft}}>
          <div className="request-loader-before"  style={{animationDelay:(prompt2Data.businesssavy.animationDelay+0.6)+"s"}}  ></div>
          <p style={{fontSize: prompt2Data.businesssavy.fontFamily+'px', lineHeight: (prompt2Data.businesssavy.fontFamily*0.8)+'px'}}>BUSINESS<br/>SAVVY<br/><span style={{fontSize: (prompt2Data.businesssavy.fontFamily*0.75)+'px'}} >{Math.floor(prompt2Data.businesssavy.percent)}%</span></p>
          <div className="request-loader-after"  style={{animationDelay:(prompt2Data.businesssavy.animationDelay+0.5)+"s"}}  ></div>
          <div className="request-loader-after2"  style={{animationDelay:(prompt2Data.businesssavy.animationDelay+1.5)+"s"}}  ></div>
          <div className="request-loader-after3"  style={{animationDelay:(prompt2Data.businesssavy.animationDelay+2.5)+"s"}}  ></div>
        </div>
        </div>
        <div>
        <div className="request-loader" style={{width: (prompt2Data.innovation.width)+'px', height:(prompt2Data.innovation.width)+'px', marginTop:prompt2Data.innovation.marginTop, marginLeft: prompt2Data.innovation.marginLeft}}>
          <div className="request-loader-before" style={{animationDelay:(prompt2Data.innovation.animationDelay+0.6)+"s"}}   ></div>
          <p style={{fontSize: prompt2Data.innovation.fontFamily+'px', lineHeight: (prompt2Data.innovation.fontFamily*0.8)+'px'}}>INNOVATION<br/><span style={{fontSize: (prompt2Data.innovation.fontFamily*0.75)+'px'}} >{Math.floor(prompt2Data.innovation.percent)}%</span></p>
          <div className="request-loader-after"  style={{animationDelay:(prompt2Data.innovation.animationDelay+0.5)+"s"}}  ></div>
          <div className="request-loader-after2"  style={{animationDelay:(prompt2Data.innovation.animationDelay+1.5)+"s"}}  ></div>
          <div className="request-loader-after3"  style={{animationDelay:(prompt2Data.innovation.animationDelay+2.5)+"s"}}  ></div>
        </div>
        </div>
        <div>
        <div className="request-loader" style={{width: (prompt2Data.training.width)+'px', height:(prompt2Data.training.width)+'px', marginTop:prompt2Data.training.marginTop, marginLeft: prompt2Data.training.marginLeft}}>
          <div className="request-loader-before"  style={{animationDelay:(prompt2Data.training.animationDelay+0.6)+"s"}}  ></div>
          <p style={{fontSize: prompt2Data.training.fontFamily+'px', lineHeight: (prompt2Data.training.fontFamily*0.8)+'px'}}>TRAINING<br/><span style={{fontSize: (prompt2Data.training.fontFamily*0.75)+'px'}} >{Math.floor(prompt2Data.training.percent)}%</span></p>
          <div className="request-loader-after"  style={{animationDelay:(prompt2Data.training.animationDelay+0.5)+"s"}}  ></div>
          <div className="request-loader-after2"  style={{animationDelay:(prompt2Data.training.animationDelay+1.5)+"s"}}  ></div>
          <div className="request-loader-after3"  style={{animationDelay:(prompt2Data.training.animationDelay+2.5)+"s"}}  ></div>
        </div>
        </div>
       
         </>
        )}

      </div>
      <div className="column column-3">
        {(prompt3Data != null)  &&
          (<>
            <div className="prompt3DataItem" >
              <div className="prompt3DataItemContent" style={{animationDelay:(prompt3Data.womensuniquejourneystosuccess.animationDelay)+"s",height:(prompt3Data.womensuniquejourneystosuccess.width)+'px', marginLeft: `calc(50% - ${(prompt3Data.womensuniquejourneystosuccess.percent*1.5)/2+'%'})`, width:(prompt3Data.womensuniquejourneystosuccess.percent*1.5)+'%'}}></div>
              
              <div className="prompt3DataItemText" style={{height:(prompt3Data.womensuniquejourneystosuccess.width)+'px'}}>
                <p style={{fontSize: prompt3Data.womensuniquejourneystosuccess.fontFamily+'px', lineHeight: (prompt3Data.womensuniquejourneystosuccess.fontFamily*0.8)+'px'}}>WOMEN'S UNIQUE<br/>JOURNEYS TO SUCCESS<br/><span style={{fontSize: (prompt3Data.womensuniquejourneystosuccess.fontFamily*0.75)+'px'}} >{Math.floor(prompt3Data.womensuniquejourneystosuccess.percent)}%</span></p>
              </div>
            </div>

            <div className="prompt3DataItem" >
              <div className="prompt3DataItemContent" style={{animationDelay:(prompt3Data.missingnarratives.animationDelay)+"s",height:(prompt3Data.missingnarratives.width)+'px', marginLeft: `calc(50% - ${(prompt3Data.missingnarratives.percent*1.5)/2+'%'})`, width:(prompt3Data.missingnarratives.percent*1.5)+'%' }}></div>
              
              <div className="prompt3DataItemText" style={{height:(prompt3Data.missingnarratives.width)+'px'}}>
                <p style={{fontSize: prompt3Data.missingnarratives.fontFamily+'px', lineHeight: (prompt3Data.missingnarratives.fontFamily*0.8)+'px'}}>MISSING<br/>NARRATIVES<br/><span style={{fontSize: (prompt3Data.missingnarratives.fontFamily*0.75)+'px'}} >{Math.floor(prompt3Data.missingnarratives.percent)}%</span></p>
              </div>
            </div>

            <div className="prompt3DataItem" >
              <div className="prompt3DataItemContent" style={{animationDelay:(prompt3Data.biasesinarthistory.animationDelay)+"s",height:(prompt3Data.biasesinarthistory.width)+'px', marginLeft: `calc(50% - ${(prompt3Data.biasesinarthistory.percent*1.5)/2+'%'})`, width:(prompt3Data.biasesinarthistory.percent*1.5)+'%' }}></div>
              
              <div className="prompt3DataItemText" style={{height:(prompt3Data.biasesinarthistory.width)+'px'}}>
                <p style={{fontSize: prompt3Data.biasesinarthistory.fontFamily+'px', lineHeight: (prompt3Data.biasesinarthistory.fontFamily*0.8)+'px'}}>BIASES IN<br/>ART HISTORY<br/><span style={{fontSize: (prompt3Data.biasesinarthistory.fontFamily*0.75)+'px'}} >{Math.floor(prompt3Data.biasesinarthistory.percent)}%</span></p>
              </div>
            </div>

            <div className="prompt3DataItem" >
              <div className="prompt3DataItemContent" style={{animationDelay:(prompt3Data.howmuchislefttodiscover.animationDelay)+"s",height:(prompt3Data.howmuchislefttodiscover.width)+'px', marginLeft: `calc(50% - ${(prompt3Data.howmuchislefttodiscover.percent*1.5)/2+'%'})`, width:(prompt3Data.howmuchislefttodiscover.percent*1.5)+'%'}}></div>
              
              <div className="prompt3DataItemText" style={{height:(prompt3Data.howmuchislefttodiscover.width)+'px'}}>
                <p style={{fontSize: prompt3Data.howmuchislefttodiscover.fontFamily+'px', lineHeight: (prompt3Data.howmuchislefttodiscover.fontFamily*0.8)+'px'}}>HOW MUCH IS<br/>LEFT TO DISCOVER<br/><span style={{fontSize: (prompt3Data.howmuchislefttodiscover.fontFamily*0.75)+'px'}} >{Math.floor(prompt3Data.howmuchislefttodiscover.percent)}%</span></p>
              </div>
            </div>

            <div className="prompt3DataItem" >
              <div className="prompt3DataItemContent" style={{animationDelay:(prompt3Data.somethingelse.animationDelay)+"s",height:(prompt3Data.somethingelse.width)+'px', marginLeft: `calc(50% - ${(prompt3Data.somethingelse.percent*1.5)/2+'%'})`, width:(prompt3Data.somethingelse.percent*1.5)+'%'}}></div>
              
              <div className="prompt3DataItemText" style={{height:(prompt3Data.somethingelse.width)+'px'}}>
                <p style={{fontSize: prompt3Data.somethingelse.fontFamily+'px', lineHeight: (prompt3Data.somethingelse.fontFamily*0.8)+'px'}}>SOMETHING ELSE...<br/><span style={{fontSize: (prompt3Data.somethingelse.fontFamily*0.75)+'px'}} >{Math.floor(prompt3Data.somethingelse.percent)}%</span></p>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="column column-4">
        <div className="wordCloud">
          <ReactWordcloud maxWords={50} words={prompt4Cloud} options={options} callbacks={reactWordcloudCallbacks}/>
        </div>

        <div className="front-view">

          
            <div className="line"></div>
            <div className="quote">
              {latestPrompt4}
            </div>

          
            <div className="quote2">

            </div>
          

            <div className="panel">
            <div className="promptText">
            </div>
                <div className="promptText">

                  <p>{textPrompt[textPromptIndex]}</p>

                </div>
            </div>
          

        </div>


      </div>

      
    </div>
    );



}




