import React from 'react';
import { ReactComponent as PlayIcon } from '../playericons/play.svg';
import { ReactComponent as PauseIcon } from '../playericons/pause.svg';
import { ReactComponent as BackIcon } from '../playericons/back.svg';
import { ReactComponent as ForwardIcon } from '../playericons/forward.svg';
import ReactGA from 'react-ga';

export default class Video extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = { play: false };
	    this.video = React.createRef();
	    
	  }


	  handleExitFullScreen = () => {
	  	this.video.pause();
	  	this.setState({ play: false });
	  }

	  handleFullScreen = () => {
	  	if(document.fullscreen === false || document.mozFullScreen === false || document.webkitIsFullScreen === false) {
	 		this.video.pause();
	  		this.setState({ play: false });
	 	}
	  }

	play = () => {
	    
	    if (this.video.paused === false) {
	    	this.video.pause();
	    	this.setState({ play: false });


	    } else {

	    	

	    	this.setState({ play: true });
	        this.video.play();

	        if (this.video.mozRequestFullScreen) {
				this.video.mozRequestFullScreen();
			} else if (this.video.webkitRequestFullScreen) {
				this.video.webkitRequestFullScreen();
			} 


			ReactGA.event({
			  category: 'Web App',
			  action: 'Start Video Stop '+this.props.artwork.id
			});


	    }
	}


	mouseDown = (e) => {
		window.addEventListener('touchmove', this.mouseMove);
		window.addEventListener('touchend', this.mouseUp);
	};


	mouseMove = (e) => {
		
		if (typeof e.touches != "undefined") {
		    this.positionHandle(e.touches[0].clientX);
		    this.video.currentTime = ((e.touches[0].clientX- this.timeline.offsetLeft)  / this.timeline.offsetWidth) * this.video.duration;
		}else{
			this.positionHandle(e.pageX);
			this.video.currentTime = ((e.pageX- this.timeline.offsetLeft) / this.timeline.offsetWidth) * this.video.duration;
		}
	}


	positionHandle = (position) => {
	  // Width of the timeline
		var timelineWidth = this.timeline.offsetWidth;

		// Left position of the handle
		var handleLeft = position - this.timeline.offsetLeft;
		

		

		if (handleLeft >= 0 && handleLeft <= timelineWidth) {
			this.handle.style.width = handleLeft + "px";
		}

		if (handleLeft < 0) {
			this.handle.style.width = "1px";
		}
		if (handleLeft > timelineWidth) {
			this.handle.style.width = timelineWidth + "px";
		}
	};



	mouseUp = (e) => {
		window.removeEventListener('touchmove', this.mouseMove);
		window.removeEventListener('touchend', this.mouseUp);
	};

  componentDidMount() {

  	
  	this.video.addEventListener("ended",this.onEnded);
  	this.video.addEventListener('canplaythrough', this.onCanPlayThrough);
    this.video.addEventListener("timeupdate", this.onUpdateTime);
    this.video.addEventListener("webkitendfullscreen", this.handleExitFullScreen);
    this.video.addEventListener("fullscreenchange", this.handleFullScreen);
	this.video.addEventListener("mozfullscreenchange", this.handleFullScreen);
	this.video.addEventListener("webkitfullscreenchange", this.handleFullScreen);

	this.video.load();

	

  }

   componentWillUnmount() {
        this.video.removeEventListener('canplaythrough', this.onCanPlayThrough);
    	this.video.removeEventListener("timeupdate", this.onUpdateTime);
    	this.video.removeEventListener("ended",this.onEnded);
    	this.video.removeEventListener("webkitendfullscreen", this.handleExitFullScreen);
    	this.video.addEventListener("fullscreenchange", this.handleFullScreen);
		this.video.addEventListener("mozfullscreenchange", this.handleFullScreen);
		this.video.addEventListener("webkitfullscreenchange", this.handleFullScreen);
    }

    onEnded = () => {
    	this.setState({ play: false });
		this.video.currentTime = 0;
		this.positionHandle(this.timeline.offsetLeft);

		

    }

    onCanPlayThrough = () => {
    	var timeleft = Math.floor(this.video.duration-this.video.currentTime).toString(); 
	    this.remindingTime.innerHTML = this.formatSecondsAsTime(timeleft);
    }


    onUpdateTime = () => {
    	let ratio = this.video.currentTime / this.video.duration;
	    let position = this.timeline.offsetWidth * ratio;
	    this.positionHandle(position+this.timeline.offsetLeft);
	    

	    var currTime = Math.floor(this.video.currentTime).toString(); 
	    this.timeSpent.innerHTML = this.formatSecondsAsTime(currTime);

	    var timeleft = Math.floor(this.video.duration-this.video.currentTime).toString(); 
	    this.remindingTime.innerHTML = this.formatSecondsAsTime(timeleft);
    }


  	formatSecondsAsTime(secs, format) {
		var hr  = Math.floor(secs / 3600);
		var min = Math.floor((secs - (hr * 3600))/60);
		var sec = Math.floor(secs - (hr * 3600) -  (min * 60));

		if (min < 10){ 
			min = "0" + min; 
		}
		if (sec < 10){ 
			sec  = "0" + sec;
		}

		return min + ':' + sec;
	}

	



    render() {

 
        return  (
        	<>
        	<div id="timeline" ref={(timeline) => { this.timeline = timeline }} onClick={this.mouseMove} >
			    <div id="handle" onTouchStart={this.mouseDown}  ref={(handle) => { this.handle = handle }} style={{ backgroundColor: this.props.color }} />
			</div>
			<div className="audio-controls-time-container"><p ref={(timeSpent) => { this.timeSpent = timeSpent }}>00:00</p><p ref={(remindingTime) => { this.remindingTime = remindingTime }}></p></div>
        	<div className="audio-controls-buttons">
        			<div onClick={this.play} className="icon" >{!this.state.play ? <PlayIcon fill={this.props.color} />  : <PauseIcon fill={this.props.color} />}</div>
        	</div>

        	<div className="video-player" style={{display: this.video.paused === false ? 'block' : 'none'}}>
		       <video  ref={(video) => { this.video = video } } id="home-video" >
			        <source src={this.props.videoPath} type="video/mp4"/>
			   </video>
		    </div>


            </>
            )
    }
}