import React from 'react';
import { useState, useEffect } from 'react';
import Header from "../components/Header";
import Div100vh from 'react-div-100vh';
import DeviceOrientation, { Orientation } from 'react-screen-orientation';
import Plx from 'react-plx';
import { useNavigate } from "react-router-dom";
import animateScroll from 'animated-scroll-to';



const titleData = [
  {
    start: 'self',
    startOffset: 330,
    duration: 220,
    properties: [
    {
          startValue: 1,
          endValue: 0,
          property: 'opacity',
    }
    ],
  },
];




const scrollDownData= [
  {
    start: 'self',
    startOffset: 100,
    duration: 100,
    properties: [
    {
          startValue: 1,
          endValue: 0,
          property: 'opacity',
    }
    ],
  },
];

const title1Data = [
	{
		start: '.trigger-image-1',
		duration: '20vh',
		properties: [
		  {
		    startValue: 0,
		    endValue: 1,
		    property: 'opacity',
		  },
		],
	}
	,
	{
		start: '.trigger-image-1',
		startOffset: '90vh',
		duration: '30vh',
		properties: [
		  
		  {
		    startValue: 1,
		    endValue: 0,
		    property: 'opacity',
		  },
		  {
		    startValue: 0,
		    endValue: -100,
		    unit:"%",
		    property: 'translateY',
		  },
		],
	},
];

const title2Data = [
	{
		start: '.trigger-image-1',
		duration: '20vh',
		startOffset: '110vh',
		properties: [
		  {
		    startValue: 0,
		    endValue: 1,
		    property: 'opacity',
		  },
		],
	}
	,
	{
		start: '.trigger-image-1',
		startOffset: '170vh',
		duration: '30vh',
		properties: [
		  
		  {
		    startValue: 1,
		    endValue: 0,
		    property: 'opacity',
		  },
		  {
		    startValue: 0,
		    endValue: -100,
		    unit:"%",
		    property: 'translateY',
		  },
		],
	},
];

const title3Data = [
	{
		start: '.trigger-image-1',
		duration: '20vh',
		startOffset: '190vh',
		properties: [
		  {
		    startValue: 0,
		    endValue: 1,
		    property: 'opacity',
		  },
		],
	}
	,
	{
		start: '.trigger-image-1',
		startOffset: '250vh',
		duration: '30vh',
		properties: [
		  
		  {
		    startValue: 1,
		    endValue: 0,
		    property: 'opacity',
		  },
		   {
		    startValue: 0,
		    endValue: -100,
		    unit:"%",
		    property: 'translateY',
		  },
		],
	},
];

const title4Data = [
	{
		start: '.trigger-image-1',
		duration: '20vh',
		startOffset: '270vh',
		properties: [
		  {
		    startValue: 0,
		    endValue: 1,
		    property: 'opacity',
		  },
		],
	}
	,
	{
		start: '.trigger-image-1',
		startOffset: '310vh',
		duration: '10vh',
		properties: [
		  
		  {
		    startValue: 1,
		    endValue: 0,
		    property: 'opacity',
		  },
		   {
		    startValue: 0,
		    endValue: -100,
		    unit:"%",
		    property: 'translateY',
		  }
		],
	},
];



const title7Data = [
	{
		start: '.trigger-image-1',
		duration: '20vh',
		startOffset: '330vh',
		properties: [
		  {
		    startValue: 0,
		    endValue: 1,
		    property: 'opacity',
		  },
		],
	}
	,
	{
		start: '.trigger-image-1',
		startOffset: '410vh',
		duration: '30vh',
		properties: [
		  
		  {
		    startValue: 1,
		    endValue: 0,
		    property: 'opacity',
		  },
		   {
		    startValue: 0,
		    endValue: -100,
		    unit:"%",
		    property: 'translateY',
		  }
		],
	},
];

const title8Data = [
	{
		start: '.trigger-image-1',
		duration: '20vh',
		startOffset: '430vh',
		properties: [
		  {
		    startValue: 0,
		    endValue: 1,
		    property: 'opacity',
		  },
		],
	}
	,
	{
		start: '.trigger-image-1',
		startOffset: '470vh',
		duration: '30vh',
		properties: [
		  
		  {
		    startValue: 1,
		    endValue: 0,
		    property: 'opacity',
		  },
		  {
		    startValue: 0,
		    endValue: -100,
		    unit:"%",
		    property: 'translateY',
		  }
		],
	},
];

const title8BisData = [
	{
		start: '.trigger-image-1',
		duration: '20vh',
		startOffset: '480vh',
		properties: [
		  {
		    startValue: 0,
		    endValue: 1,
		    property: 'opacity',
		  },
		],
	}
	,
	{
		start: '.trigger-image-1',
		startOffset: '520vh',
		duration: '30vh',
		properties: [
		  
		  {
		    startValue: 1,
		    endValue: 0,
		    property: 'opacity',
		  },
		  {
		    startValue: 0,
		    endValue: -100,
		    unit:"%",
		    property: 'translateY',
		  }
		],
	},
];

const title8BisBisData = [
	{
		start: '.trigger-image-1',
		duration: '20vh',
		startOffset: '540vh',
		properties: [
		  {
		    startValue: 0,
		    endValue: 1,
		    property: 'opacity',
		  },
		],
	}
	,
	{
		start: '.trigger-image-1',
		startOffset: '580vh',
		duration: '30vh',
		properties: [
		  
		  {
		    startValue: 1,
		    endValue: 0,
		    property: 'opacity',
		  },
		  {
		    startValue: 0,
		    endValue: -100,
		    unit:"%",
		    property: 'translateY',
		  }
		],
	},
];

const title8BisBisBisData = [
	{
		start: '.trigger-image-1',
		duration: '20vh',
		startOffset: '600vh',
		properties: [
		  {
		    startValue: 0,
		    endValue: 1,
		    property: 'opacity',
		  },
		],
	}
	,
	{
		start: '.trigger-image-1',
		startOffset: '640vh',
		duration: '30vh',
		properties: [
		  
		  {
		    startValue: 1,
		    endValue: 0,
		    property: 'opacity',
		  },
		  {
		    startValue: 0,
		    endValue: -100,
		    unit:"%",
		    property: 'translateY',
		  }
		],
	},
];



const title9Data = [
	{
		start: '.trigger-image-1',
		duration: '20vh',
		startOffset: '660vh',
		properties: [
		  {
		    startValue: 0,
		    endValue: 1,
		    property: 'opacity',
		  },
		],
	}
	,
	{
		start: '.trigger-image-1',
		startOffset: '750vh',
		duration: '30vh',
		properties: [
		  
		  {
		    startValue: 1,
		    endValue: 0,
		    property: 'opacity',
		  },
		  {
		    startValue: 0,
		    endValue: -100,
		    unit:"%",
		    property: 'translateY',
		  }
		],
	},
];


const title10Data = [
	{
		start: '.trigger-image-1',
		duration: '20vh',
		startOffset: '800vh',
		properties: [
		  {
		    startValue: 0,
		    endValue: 1,
		    property: 'opacity',
		  },
		  {
		    startValue: 100,
		    endValue: 0,
		    unit:"%",
		    property: 'translateY',
		  }
		],
	}
	
];


const image1Data = [
	{
		start: '.trigger-image-1',
		startOffset: '10vh',
		duration: '20vh',
		properties: [
		  {
		    startValue: 0,
		    endValue: 1,
		    property: 'opacity',
		  },
		],
	},
	{
		start: '.trigger-image-1',
		startOffset: '250vh',
		duration: '30vh',
		properties: [
		  {
		    startValue: 1,
		    endValue: 0,
		    property: 'opacity',
		  },
		],
	},
]


const scrollDownData2= [
  {
    start: '.trigger-image-1',
    startOffset: '30vh',
    duration: 100,
    properties: [
    {
          startValue: 0,
          endValue: 1,
          property: 'opacity',
    }
    ],
  },
  {
    start: '.trigger-image-1',
    startOffset: '70vh',
    duration: 100,
    properties: [
    {
          startValue: 1,
          endValue: 0,
          property: 'opacity',
    }
    ],
  },
];

const caption1Data = [
	{
		start: '.trigger-image-1',
		startOffset: '10vh',
		duration: '20vh',
		properties: [
		  {
		    startValue: 0,
		    endValue: 1,
		    property: 'opacity',
		  },
		],
	},
	{
		start: '.trigger-image-1',
		startOffset: '100vh',
		duration: '30vh',
		properties: [
		  {
		    startValue: 1,
		    endValue: 0,
		    property: 'opacity',
		  },
		],
	},
]

const imageOverlayData = [
	{
		start: '.trigger-image-1',
		startOffset: '50vh',
		duration: '40vh',
		properties: [
		  {
		    startValue: 0,
		    endValue: 1,
		    property: 'opacity',
		  },
		],
	},
	{
		start: '.trigger-image-1',
		startOffset: '250vh',
		duration: '30vh',
		properties: [
		  {
		    startValue: 1,
		    endValue: 0,
		    property: 'opacity',
		  },
		],
	},
]

const image2Data = [
	{
		start: '.trigger-image-1',
		startOffset: '110vh',
		duration: '40vh',
		properties: [
		  {
		    startValue: 100,
	        endValue: 0,
	        unit: '%',
		    property: 'translateX',
		  },
		],
	},
	{
		start: '.trigger-image-1',
		startOffset: '250vh',
		duration: '30vh',
		properties: [
		  {
		    startValue: 1,
		    endValue: 0,
		    property: 'opacity',
		  },
		],
	},
]

const caption2Data = [
	{
		start: '.trigger-image-1',
		startOffset: '110vh',
		duration: '20vh',
		properties: [
		  {
		    startValue: 0,
		    endValue: 1,
		    property: 'opacity',
		  },
		],
	},
	{
		start: '.trigger-image-1',
		startOffset: '250vh',
		duration: '30vh',
		properties: [
		  {
		    startValue: 1,
		    endValue: 0,
		    property: 'opacity',
		  },
		],
	},
]


const image3Data = [
	{
		start: '.trigger-image-1',
		startOffset: '270vh',
		duration: '20vh',
		properties: [
		  {
		    startValue: 0,
		    endValue: 1,
		    property: 'opacity',
		  },
		],
	},
	{
		start: '.trigger-image-1',
		startOffset: '310vh',
		duration: '30vh',
		properties: [
		  {
		    startValue: 1,
		    endValue: 0,
		    property: 'opacity',
		  },
		  {
		    startValue: 0,
		    endValue: -100,
		    unit:"%",
		    property: 'translateY',
		  },
		],
	},
]






const image6Data = [
	{
		start: '.trigger-image-1',
		startOffset: '320vh',
		duration: '20vh',
		properties: [
		  {
		    startValue: 0,
		    endValue: 1,
		    property: 'opacity',
		  },
		],
	},
	{
		start: '.trigger-image-1',
		startOffset: '720vh',
		duration: '30vh',
		properties: [
		  {
		    startValue: 1,
		    endValue: 0,
		    property: 'opacity',
		  },
		],
	},
]

const caption6Data = [
	{
		start: '.trigger-image-1',
		startOffset: '320vh',
		duration: '20vh',
		properties: [
		  {
		    startValue: 0,
		    endValue: 1,
		    property: 'opacity',
		  },
		],
	},
	{
		start: '.trigger-image-1',
		startOffset: '360vh',
		duration: '30vh',
		properties: [
		  {
		    startValue: 1,
		    endValue: 0,
		    property: 'opacity',
		  },
		],
	},
]


const image6BisData = [
	{
		start: '.trigger-image-1',
		startOffset: '370vh',
		duration: '40vh',
		properties: [
		  {
		    startValue: 0,
	        endValue: -100,
	        unit: '%',
		    property: 'translateX',
		  },
		],
	}
]

const caption6BisData = [
	{
		start: '.trigger-image-1',
		startOffset: '380vh',
		duration: '20vh',
		properties: [
		  {
		    startValue: 0,
		    endValue: 1,
		    property: 'opacity',
		  },
		],
	},
	{
		start: '.trigger-image-1',
		startOffset: '500vh',
		duration: '30vh',
		properties: [
		  {
		    startValue: 1,
		    endValue: 0,
		    property: 'opacity',
		  },
		],
	},
]


const image6DataXRay2Overlay = [
	{
		start: '.trigger-image-1',
		startOffset: '480vh',
		duration: '75vh',
		properties: [
		  {
		    startValue: 0,
		    endValue: 1,
		    property: 'opacity',
		  },
		],
	},
	{
		start: '.trigger-image-1',
		startOffset: '780vh',
		duration: '20vh',
		properties: [
		  {
		    startValue: 1,
		    endValue: 0,
		    property: 'opacity',
		  },
		],
	},
]

const image7Data  = [
	{
		start: '.trigger-image-1',
		startOffset: '660vh',
		duration: '20vh',
		properties: [
		  {
		    startValue: 100,
	        endValue: 0,
	        unit: '%',
		    property: 'translateX',
		  },
		],
	},
	{
		start: '.trigger-image-1',
		startOffset: '690vh',
		duration: '30vh',
		properties: [
		  {
		    startValue: 0,
	        endValue: -100,
	        unit: '%',
		    property: 'translateX',
		  },
		],
	},
]

const image72Data  = [
	{
		start: '.trigger-image-1',
		startOffset: '690vh',
		duration: '30vh',
		properties: [
		  {
		    startValue: 100,
	        endValue: 0,
	        unit: '%',
		    property: 'translateX',
		  },
		],
	},
	{
		start: '.trigger-image-1',
		startOffset: '720vh',
		duration: '30vh',
		properties: [
		  {
		    startValue: 0,
	        endValue: -100,
	        unit: '%',
		    property: 'translateX',
		  },
		],
	},
]

const image73Data  = [
	{
		start: '.trigger-image-1',
		startOffset: '720vh',
		duration: '30vh',
		properties: [
		  {
		    startValue: 100,
	        endValue: 0,
	        unit: '%',
		    property: 'translateX',
		  },
		],
	},
	{
		start: '.trigger-image-1',
		startOffset: '750vh',
		duration: '30vh',
		properties: [
		  {
		    startValue: 0,
	        endValue: -100,
	        unit: '%',
		    property: 'translateX',
		  },
		],
	},
]




export default function Feature8DetailsView(props) {
	//const artwork = props.artwork
	const fullHeight = window.innerHeight
	const [imageURL, setImageURL] = useState("");
	const navigate = useNavigate()
	
	const goToContent = () => {	
   		animateScroll(550, { minDuration: 1500 });
  	};

  	const displayImage = (imgURL) => {
  		setImageURL(imgURL)
  	}

  	const onClickDone = () => {
  		setImageURL("")
  	}

  	useEffect(() => {
    	window.scrollTo(0, 0);

	});


	return (
		<DeviceOrientation lockOrientation={'portrait'} className="parallaxContainer feature8DetailsView">
        	<Orientation orientation='portrait' alwaysRender={false}>
		        
		        <div className="parallaxContainer-inner" style={ imageURL !== "" ? { overflow:'hidden' , backgroundColor: props.artwork.color} : {backgroundColor: props.artwork.color}}>
		        	<img className="feature2Bg" src="/img/patterns-crown.png" />
		        	
			        <Div100vh  dontresize="true" className="container">
			        	<Header hasBack={true} isFromApp={props.isFromApp} />
			       
			       		<div className="feature2Container" >
					       
					        <Plx tagName='h1' parallaxData={ titleData } >
					            Conservation scientists<br/>discovered that Gentileschi<br/>used the same self-portrait<br/>as the basis for each of<br/>these paintings. 
					        </Plx>
							
							

					        <Plx parallaxData={ scrollDownData } className="scrollDown" onClick={goToContent}>
					            <div >
					                <p>Slowly scroll through the story<br/>of how they did it.</p>
					     
					                <div className="arrow-container animated fadeInDown"   >
							          <div className="arrow-2">
							            <img src="/scroll-icon.png" alt="scroll icon" />
							          </div>
							          <div className="arrow-1 animated hinge infinite zoomInScroll"></div>
							        </div>


				                </div>
				            </Plx>
				        </div>

			        </Div100vh>

			        <div id="contentFeature"  >
			        	<div className="trigger-image-1" />
			        	<Plx tagName='h3' parallaxData={ title1Data } className='text-1-parallax'>
							First, conservators traced <span className="italic">Self-Portrait as a Lute Player</span> to create a line drawing.
						</Plx>
						<Plx tagName='h3' parallaxData={ title2Data } className='text-1-parallax'>
							Then, they overlayed the tracing onto her <span className="italic">Self-Portrait as Saint Catherine of Alexandria</span>.  
						</Plx>
						<Plx tagName='h3' parallaxData={ title3Data } className='text-1-parallax'>
							Notice that while her accessories may be different,
						</Plx>

						<Plx tagName='h3' parallaxData={ title4Data } className='text-1-parallax'>
							Her face and shoulders are identically placed.
						</Plx>

						
			        	
						<Plx parallaxData={ scrollDownData2 } className="scrollDown" >

						<div >
					                <p>Keep scrolling gradually to continue</p>
					                <img src="/scroll-icon.png" alt="scroll icon" />
				                </div>


					            
				            </Plx>
			        	

			        	<Plx parallaxData={ image1Data } className='img-1-parallax'>
			        		<div className="img-content">
			        			<img src="/img/stop8/parallax08-01-1x.jpg"  alt="img-1-parallax" />

			        			<Plx className='img-content-abs' parallaxData={ image2Data } >
			        				<img src="/img/stop8/parallax08-02-1x.jpg"  alt="img-1-parallax" />
			        			</Plx>

			        		</div>
			        	</Plx>
			        	<Plx parallaxData = {caption1Data} className='caption-parallax'>
			        		<p>The Wadsworth Atheneum Museum of Art, Hartford, Charles H. Schwartz Endowment Fund, 2014.4.1</p>
			        	</Plx>
			        	<Plx parallaxData={ caption2Data } className='caption-parallax'>
			        		<p>The National Gallery, London, NG6671 ©The National Gallery, London</p>
			        	</Plx>
			        	<Plx parallaxData={ image3Data } className='img-1-parallax'>
			        		<img src="/img/stop8/parallax08-03-1x.jpg"  alt="img-1-parallax" />
			        	</Plx>

			        	
			        	
						<Plx parallaxData={ imageOverlayData } className='img-1-parallax'>
			        		<img src="/img/stop8/parallax08-overlay-1x.png"  alt="img-1-parallax" />
			        	</Plx>

			        	
			        

			        	<Plx tagName='h3' parallaxData={ title7Data } className='text-1-parallax '>
							Then, experts x-rayed the painting of Saint Catherine to see what was underneath.
						</Plx>


						<Plx tagName='h3' parallaxData={ title8Data } className='text-1-parallax '>
							When they did,
						</Plx>
						<Plx tagName='h3' parallaxData={ title8BisData } className='text-1-parallax '>
							they saw that Saint Catherine originally wore a headwrap instead of a crown
						</Plx>
						<Plx tagName='h3' parallaxData={ title8BisBisData } className='text-1-parallax '>
							and looked straight out toward the viewer.
						</Plx>
						<Plx tagName='h3' parallaxData={ title8BisBisData } className='text-1-parallax '>
							and looked straight out toward the viewer.
						</Plx>
						<Plx tagName='h3' parallaxData={ title8BisBisBisData } className='text-1-parallax '>
							Just like in the other two paintings.
						</Plx>


						<Plx tagName='h3' parallaxData={ title9Data } className='text-1-parallax '>
							Scroll up and down to see how this underlayer aligns with each painting.	 
						</Plx>



						<Plx parallaxData={ image6Data } className='img-1-parallax'>
			        		<div className="img-content">
			        			<img src="/img/stop8/parallax08-06-2-1x.png"  alt="img-1-parallax" />

			        			<Plx className='img-content-abs2' parallaxData={ image6BisData } >
			        				<img src="/img/stop8/parallax08-06-1x.jpg"  alt="img-1-parallax" />
			        			</Plx>

			        		</div>
			        	</Plx>

			        	<Plx parallaxData = {caption6Data} className='caption-parallax'>
			        		<p>Gallerie degli Uffizi, Florence, inv. 1890 no. 8032</p>
			        	</Plx>

			        	<Plx parallaxData = {caption6BisData} className='caption-parallax'>
			        		<p>Gallerie degli Uffizi, Florence, inv. 1890 no. 8032. Permissions courtesy of the Ministero della Cultura</p>
			        	</Plx>




			        	<Plx parallaxData={ image6DataXRay2Overlay } className='img-1-parallax'>
			        		<div className="img-content">
			        			
			        			<img src="/img/stop8/parallax08-07-4-1x.png"  alt="img-1-parallax" />

			        			<Plx className='img-content-abs' parallaxData={ image7Data } >
			        				<img src="/img/stop8/parallax08-07-1-1x.png"  alt="img-1-parallax" />
			        			</Plx>
			        			<Plx className='img-content-abs' parallaxData={ image72Data } >
			        				<img src="/img/stop8/parallax08-07-2-1x.png"  alt="img-1-parallax" />
			        			</Plx>
			        			<Plx className='img-content-abs' parallaxData={ image73Data } >
			        				<img src="/img/stop8/parallax08-07-3-1x.jpg"  alt="img-1-parallax" />
			        			</Plx>

			        			<img src="/img/stop8/parallax08-07-4-1x.png"  alt="img-1-parallax" style={{position:"absolute", top:0}}/>

			        			

			        		</div>
			        	</Plx>





			        	
			        	<Plx parallaxData={ title10Data } className='img-1-parallax finalcopyFeatured8'>
			        		<h3>Consider how modern science<br/>can uncover history's mysteries<br/>and help us better understand how<br/>women artists painted.</h3>

			        		<a href="/" onClick={function(e){e.preventDefault(); if(props.isFromApp){navigate(-1);}else {window.location.href = "/"}}}>BACK TO PLAYLIST</a>
			        		

			        	</Plx>
			        	


			        	

			        </div>

			        
			    </div>
		        

		        {(imageURL !== "")  &&
		          (<Div100vh className="overlayFullScreen" >
		          	  <Header hasDone={true} clickDone = {onClickDone} />
		              <div onClick={function(e){if(e.target.nodeName !== "IMG") {setImageURL("")}}}>
		              	<img src={imageURL} alt="img parallax"/>
		              </div>
		          </Div100vh>)
		          }

        	</Orientation>


        	<Orientation orientation='landscape'  alwaysRender={false}>
	          <Div100vh  dontresize="true" className="landscapeDiv">
	            <p>For the optimal tour experience,<br/> please turn your phone to portrait mode.</p>
	          </Div100vh>
	        </Orientation>


        </DeviceOrientation>

	)


}

