import React, { useState, useEffect, useRef } from "react";
import { useInterval } from '../../helper/functions.js';


let textPrompt = ["Where would you be?", "What would you be doing?", "What are you holding?", "How are you posed?"];

export default function Column4TextPrompt(props) {

	const [textPromptIndex, setTextPromptIndex] = useState(null);

	/* ROTATE LITTLE PROMPT */
	useInterval(() => {
		//console.log("rotate prompt")
		if(textPromptIndex < textPrompt.length-1){
		  

		  setTextPromptIndex(textPromptIndex+1)
		}else {
		  setTextPromptIndex(0)
		}
	    
	}, 3000);

	return (
		<div className="promptText">
		  <p>{textPrompt[textPromptIndex]}</p>
		</div>
	);
    



}



