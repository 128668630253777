import React, { useState } from "react";
import Div100vh from 'react-div-100vh'
import Header from "../components/Header";

import prompts from '../datas/Prompts.js';
import DeviceOrientation, { Orientation } from 'react-screen-orientation'
import NotFound from '../views/NotFound';

import { useParams } from "react-router-dom";

function QuizzSection(props) {
  const quizz = prompts[useParams().id-1];
  const [input, setInput] = useState( "");
  //const [input, setInput] = useState( quizz!== undefined ? (store.get("prompt"+quizz.id) !== undefined ? store.get("prompt"+quizz.id) : "" ) : "");
  const [hasSaved, setHasSaved] = useState(false)

  if(quizz === undefined) {
    return (
      <NotFound />

      )
  }




  const handleSubmit = (e) => {

    e.preventDefault();


    

    saveTodo(input, quizz);
    
  };

  const saveTodo = (input, prompt) => {

    
    if(input != "") {
      //store.set('prompt'+prompt.id, input);
      prompt.answer = input
      
      const { v4: uuidv4 } = require('uuid');
      var userUuid = uuidv4();
      //console.log(prompt)
      //saveUserInput(input, prompt);
  ///setPompt/

      fetch("/setPrompt/"+userUuid, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": "token-value",
          },
          body: JSON.stringify(prompt),
        })
        .then((res) => res.json())
        .then((data) => {

          console.log(data)

          setHasSaved(true)

      });
    } else {
      alert("please select an answer")
    }


  
  };



  const quizzView = quizz.options.map((option, index) => {


      return (<div key={"option"+index} className={"quizzOption " + (input === option ? 'selected' : '')}  onClick={function() {setInput(option)}}><div className="letter"></div><p>{option}</p> </div>)
  })


  return (
<DeviceOrientation lockOrientation={'portrait'} >
    <Orientation orientation='portrait' alwaysRender={false}>
    {(hasSaved === false)  ?         (

      <div   className="quizzSection" style={{backgroundColor: quizz.color}}>
        <Header  hasSave={true}  isFromApp={false} clickSave = {function(){ saveTodo(input, quizz);}}/>
        <div className="quizzContent">
          <form onSubmit={handleSubmit}>
           
            <h2 dangerouslySetInnerHTML={{ __html: quizz.question }} />
            
            <div className="input">
              
              {(quizz.type === "input")  ?
             (
                <textarea
                
                  required
                  placeholder="Share your answer here..."
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                />

              ) :

             (

                <div>

                  {quizzView}

                </div>

              )}


              <input type="submit" value="SUBMIT" />
              <div style={{clear:"both"}} />
            </div>
          </form>
        </div>
      </div>

    ) : (

      <Div100vh  dontresize="true" className="thankYou" style={{backgroundColor: quizz.color}}>
        <Header  />
        <div className="thankYouContent">
          <h3>Thank you for submitting<br/>your answer!</h3>
          
        </div>
      </Div100vh>

    )}
    </Orientation>


      <Orientation orientation='landscape'  alwaysRender={false}>
            <Div100vh  dontresize="true" className="landscapeDiv">
              <p>For the optimal tour experience,<br/> please turn your phone to portrait mode.</p>
            </Div100vh>
          </Orientation>


        </DeviceOrientation>

  );
}

export default QuizzSection;