import React, { useState, useEffect} from "react";
import { useInterval } from '../../helper/functions.js';

export default function Column2(props) {


	const height = props.height
	const [prompt2Data, setPrompt2Data] = useState(null);

 
	useEffect(() => {
	    if (height > 0) getPrompt2Data();
	  }, [height]);

 	  useInterval(() => {
	    getPrompt2Data();
	  }, 30000);
  

  const getPrompt2Data = () => {
    

    fetch("/getPrompt1")
      .then((res) => res.json())
      .then((data) => {
        //console.log("prompt0")
        //console.log(data)
        


        var hasChanged = false
        Object.keys(data).forEach(key => {

            var w = ((height * data[key].percent)/100) - 10

            if(prompt2Data !== null) {
              if(prompt2Data[key].percent !== data[key].percent) {
                hasChanged = true;
              }
              
            }
            if(prompt2Data === null) {
              hasChanged = true
            }

            if(w < 80) {
              data[key].width = 100;
            }else if(w > 350) {
              data[key].width = 350;
            }else {
              data[key].width = w;
            }
            
            if(data[key].marginTop === 0) {
              data[key].marginTop = Math.floor(Math.random() * 20) - (10 +Math.floor(Math.random() * 20)) +'px';
            }
            //data[key].marginLeft = (data[key].percent < 30) ?  -Math.floor(Math.random() * 100) +'px' : -Math.floor(Math.random() * 50) +'px';

            if(data[key].percent < 20) {
              data[key].fontFamily = 18;
            }else  if(data[key].percent < 24) {
              data[key].fontFamily = 20;
            }else if(data[key].percent < 49) {
              data[key].fontFamily = 26;
            }else if(data[key].percent < 74) {
              data[key].fontFamily = 30;
            } 

        });

        if(hasChanged) {
          setPrompt2Data(data)
        }


      });

  } 


  //console.log("render col 2 ")

  return (
    <div className="column column-2">
        {(prompt2Data != null)  &&
         (<>
         <div>
        <div className="request-loader" style={{width: (prompt2Data.somethingelse.width)+'px', height:(prompt2Data.somethingelse.width)+'px', marginTop:'20px', marginLeft: prompt2Data.somethingelse.marginLeft}}>
          <div className="request-loader-before" style={{animationDelay:(prompt2Data.somethingelse.animationDelay+0.6)+"s"}}  ></div>
          <p style={{fontSize: prompt2Data.somethingelse.fontFamily+'px', lineHeight: (prompt2Data.somethingelse.fontFamily*0.8)+'px'}}>SOMETHING<br/>ELSE...<br/><span style={{fontSize: (prompt2Data.somethingelse.fontFamily*0.75)+'px'}} >{Math.floor(prompt2Data.somethingelse.percent)}%</span></p>
          <div className="request-loader-after"  style={{animationDelay:(prompt2Data.somethingelse.animationDelay+0.5)+"s"}}  ></div>
          <div className="request-loader-after2"  style={{animationDelay:(prompt2Data.somethingelse.animationDelay+1.5)+"s"}}  ></div>
          <div className="request-loader-after3"  style={{animationDelay:(prompt2Data.somethingelse.animationDelay+2.5)+"s"}}  ></div>
        </div>
        </div>
        <div>
        <div className="request-loader" style={{width: (prompt2Data.overcomingbarriers.width)+'px', height:(prompt2Data.overcomingbarriers.width)+'px', marginTop:prompt2Data.overcomingbarriers.marginTop, marginLeft: prompt2Data.overcomingbarriers.marginLeft}}>
          <div className="request-loader-before"  style={{animationDelay:(prompt2Data.overcomingbarriers.animationDelay+0.6)+"s"}}  ></div>
          <p style={{fontSize: prompt2Data.overcomingbarriers.fontFamily+'px', lineHeight: (prompt2Data.overcomingbarriers.fontFamily*0.8)+'px'}}>OVERCOMING<br/>BARRIERS<br/><span style={{fontSize: (prompt2Data.overcomingbarriers.fontFamily*0.75)+'px'}} >{Math.floor(prompt2Data.overcomingbarriers.percent)}%</span></p>
          <div className="request-loader-after"  style={{animationDelay:(prompt2Data.overcomingbarriers.animationDelay+0.5)+"s"}}  ></div>
          <div className="request-loader-after2"  style={{animationDelay:(prompt2Data.overcomingbarriers.animationDelay+1.5)+"s"}}  ></div>
          <div className="request-loader-after3"  style={{animationDelay:(prompt2Data.overcomingbarriers.animationDelay+2.5)+"s"}}  ></div>
        </div>
        </div>
        <div>
        <div className="request-loader" style={{width: (prompt2Data.fame.width)+'px', height:(prompt2Data.fame.width)+'px', marginTop:prompt2Data.fame.marginTop, marginLeft: prompt2Data.fame.marginLeft}}>
          <div className="request-loader-before"  style={{animationDelay:(prompt2Data.fame.animationDelay+0.6)+"s"}}  ></div>
          <p style={{fontSize: prompt2Data.fame.fontFamily+'px', lineHeight: (prompt2Data.fame.fontFamily*0.8)+'px'}}>FAME<br/><span style={{fontSize: (prompt2Data.fame.fontFamily*0.75)+'px'}} >{Math.floor(prompt2Data.fame.percent)}%</span></p>
          <div className="request-loader-after"  style={{animationDelay:(prompt2Data.fame.animationDelay+0.5)+"s"}}  ></div>
          <div className="request-loader-after2"  style={{animationDelay:(prompt2Data.fame.animationDelay+1.5)+"s"}}  ></div>
          <div className="request-loader-after3"  style={{animationDelay:(prompt2Data.fame.animationDelay+2.5)+"s"}}  ></div>
        </div>
        </div>
        <div>
        <div className="request-loader" style={{width: (prompt2Data.businesssavy.width)+'px', height:(prompt2Data.businesssavy.width)+'px', marginTop:prompt2Data.businesssavy.marginTop, marginLeft: prompt2Data.businesssavy.marginLeft}}>
          <div className="request-loader-before"  style={{animationDelay:(prompt2Data.businesssavy.animationDelay+0.6)+"s"}}  ></div>
          <p style={{fontSize: prompt2Data.businesssavy.fontFamily+'px', lineHeight: (prompt2Data.businesssavy.fontFamily*0.8)+'px'}}>BUSINESS<br/>SAVVY<br/><span style={{fontSize: (prompt2Data.businesssavy.fontFamily*0.75)+'px'}} >{Math.floor(prompt2Data.businesssavy.percent)}%</span></p>
          <div className="request-loader-after"  style={{animationDelay:(prompt2Data.businesssavy.animationDelay+0.5)+"s"}}  ></div>
          <div className="request-loader-after2"  style={{animationDelay:(prompt2Data.businesssavy.animationDelay+1.5)+"s"}}  ></div>
          <div className="request-loader-after3"  style={{animationDelay:(prompt2Data.businesssavy.animationDelay+2.5)+"s"}}  ></div>
        </div>
        </div>
        <div>
        <div className="request-loader" style={{width: (prompt2Data.innovation.width)+'px', height:(prompt2Data.innovation.width)+'px', marginTop:prompt2Data.innovation.marginTop, marginLeft: prompt2Data.innovation.marginLeft}}>
          <div className="request-loader-before" style={{animationDelay:(prompt2Data.innovation.animationDelay+0.6)+"s"}}   ></div>
          <p style={{fontSize: prompt2Data.innovation.fontFamily+'px', lineHeight: (prompt2Data.innovation.fontFamily*0.8)+'px'}}>INNOVATION<br/><span style={{fontSize: (prompt2Data.innovation.fontFamily*0.75)+'px'}} >{Math.floor(prompt2Data.innovation.percent)}%</span></p>
          <div className="request-loader-after"  style={{animationDelay:(prompt2Data.innovation.animationDelay+0.5)+"s"}}  ></div>
          <div className="request-loader-after2"  style={{animationDelay:(prompt2Data.innovation.animationDelay+1.5)+"s"}}  ></div>
          <div className="request-loader-after3"  style={{animationDelay:(prompt2Data.innovation.animationDelay+2.5)+"s"}}  ></div>
        </div>
        </div>
        <div>
        <div className="request-loader" style={{width: (prompt2Data.training.width)+'px', height:(prompt2Data.training.width)+'px', marginTop:prompt2Data.training.marginTop, marginLeft: prompt2Data.training.marginLeft}}>
          <div className="request-loader-before"  style={{animationDelay:(prompt2Data.training.animationDelay+0.6)+"s"}}  ></div>
          <p style={{fontSize: prompt2Data.training.fontFamily+'px', lineHeight: (prompt2Data.training.fontFamily*0.8)+'px'}}>TRAINING<br/><span style={{fontSize: (prompt2Data.training.fontFamily*0.75)+'px'}} >{Math.floor(prompt2Data.training.percent)}%</span></p>
          <div className="request-loader-after"  style={{animationDelay:(prompt2Data.training.animationDelay+0.5)+"s"}}  ></div>
          <div className="request-loader-after2"  style={{animationDelay:(prompt2Data.training.animationDelay+1.5)+"s"}}  ></div>
          <div className="request-loader-after3"  style={{animationDelay:(prompt2Data.training.animationDelay+2.5)+"s"}}  ></div>
        </div>
        </div>
       
         </>
        )}

      </div>
    );
    



}



