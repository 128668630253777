import React from 'react';
import { useState, useEffect } from 'react';
import Header from "../components/Header";
import Player from "../components/Player";
import Div100vh from 'react-div-100vh';
import DeviceOrientation, { Orientation } from 'react-screen-orientation'
import { ReactComponent as PlayIcon } from '../playericons/play.svg';


export default function AudioDetailsView(props) {
  
  const artwork = props.artwork
  const audio = artwork.audioPath;
  const [currentView, setCurrentView] = useState(0)
  const [isLandingScreen, setIsLandingScreen] = useState(props.isKiosk === undefined ? false : true)
  const [isAutoPlay, setAuPlay] = useState(false)

  useEffect(() => {
      window.scrollTo(0, 0);

  });

  const  switchView = (i) => {
   
	   setCurrentView(i)
  }

  const tapLandingScreen = () => {
    setIsLandingScreen(false)
    setAuPlay(true)
  }

  const resetLandingScreen = () => {
    setIsLandingScreen(true)
    setAuPlay(false)
  }


  let backgroundImage = artwork.thumbnailPath;
  if(props.isKiosk) {
    backgroundImage = artwork.thumbnailPathKiosk
  }

  const galleryView = artwork.imgGallery != undefined ? artwork.imgGallery.map((imgGallery, index) => {

      return (<div key={"galleryitem"+index}>
                <img src={imgGallery.url} />
                <div className="descriptionAudio" style={{ color: artwork.color }}>
                    <h1>{imgGallery.title}</h1>
                    <h3>{imgGallery.artist}</h3>
                  </div>
              </div>)
    
  }) : null;

  if(isLandingScreen && props.isKiosk) {

    let backgroundImageKiosk = artwork.imgPathKiosk;

    return (
      <Div100vh  dontresize="true" className="landingScreenKiosk" onClick={tapLandingScreen}  style={{ backgroundImage: `url(${backgroundImageKiosk})` }} >
        <div>
          <h1>Credit for this painting was mistakenly given to a male artist. Discover why.</h1>
            <PlayIcon className="playIcon" fill="#fff" />
          <h1>Tap the screen for a 2-minute audio recording.</h1>
        </div>
      </Div100vh>

    )
  }

  if(!isLandingScreen && props.isKiosk) {
    return (
    <Div100vh  dontresize="true" className="audiodetails kioskAudioDetails" >
        
         {(artwork != null)  &&
         (
          <div className="container">

            
            
            <div className={` ${currentView === 1 ? "audiodetails-image-container margins" : " audiodetails-image-container "}`}>
              
              <div className={` ${currentView === 0 ? "" : "hidden"}`}>
                <img src={backgroundImage} className="image-details" />
                <div className="descriptionAudio" style={{ color: artwork.color }}>
                <h1>{artwork.name}, {artwork.year}</h1>
                <h3>{artwork.artist}</h3>
                </div>
              </div>

              <div className={` ${currentView === 1 ? "text-details" : " text-details hidden"}`}>
                <div className="descriptionAudio" style={{ color: artwork.color }}>
                  <h1>{artwork.name}, {artwork.year}</h1>
                  <h3>{artwork.artist}</h3>
                </div>
                <div className="text-content-details"   dangerouslySetInnerHTML={{ __html: artwork.description }}></div>
              </div>

              {/* <div className={` ${currentView === 1 ? "fade-away" : " fade-away hidden"}`}></div> */}
            </div>


            <div className={` ${currentView === 1 ? "audio-controls hidden" : " audio-controls "}`}>
              

                <Player audio={audio} color={artwork.color} isKiosk={props.isKiosk} artwork={artwork} isAutoPlay={isAutoPlay} audioFinished={resetLandingScreen}/>
            </div>
            
            
            
          </div>
          )}
    
    </Div100vh>

    )

  }


    return (
    	<DeviceOrientation lockOrientation={(props.isKiosk === undefined) ? 'portrait' : 'landscape'}>
        <Orientation orientation={(props.isKiosk === undefined) ? 'portrait' : 'landscape'} alwaysRender={false}>
          <div  className="audiodetails nokiosk" style={{minHeight:'100vh'}}>
       

          {(props.isKiosk === undefined)  &&
           (<Header hasBack={true} isFromApp={props.isFromApp}/>)}
        
           {(artwork != null)  &&
           (
            <div className="container">

            	<div className="audiodetails-toppart">
	              

	              <div className="audio-toggler">
                  <div onClick={function(e){switchView(0)} } className={` ${currentView === 0 ? "active" : ""}`} style={{ color: currentView === 0 ? "#fff" : artwork.color, borderColor:artwork.color, background: (currentView === 1 || currentView === 2) ? "#fff" : artwork.color}}>Audio</div>
                  
                  {(artwork.imgGallery != undefined)  && (<div onClick={function(e){switchView(2)} } className={` ${currentView === 2 ? "active" : ""}`} style={{ color: currentView === 2 ? "#fff" : artwork.color, borderColor:artwork.color, borderLeftWidth:0,borderRightWidth:0, background: (currentView === 0  || currentView === 1) ? "#fff" : artwork.color}}>Gallery</div> )}

                  <div onClick={function(e){switchView(1)} } className={` ${currentView === 1 ? "active" : ""}`} style={{ color: currentView === 1 ? "#fff" : artwork.color, borderColor:artwork.color, background: (currentView === 0  || currentView === 2) ? "#fff" : artwork.color}}>Text</div>
              </div>
	            </div>
              <div className={` ${currentView === 1 ? "audiodetails-image-container margins" : " audiodetails-image-container "}`}>
              	

                <div className={` ${currentView === 0 ? "" : "hidden"}`}>
                  
                  <img src={backgroundImage} className="image-details" />
                  <div className="descriptionAudio" style={{ color: artwork.color }}>
                  <h1>{artwork.name}, {artwork.year}</h1>
                  <h3>{artwork.artist}</h3>
                  </div>
                </div>


              	<div className={` ${currentView === 1 ? "text-details" : " text-details hidden"}`}>
                  <div className="descriptionAudio" style={{ color: artwork.color }}>
                    <h1>{artwork.name}, {artwork.year}</h1>
                    <h3>{artwork.artist}</h3>
                  </div>
				          <div className="text-content-details"   dangerouslySetInnerHTML={{ __html: artwork.description }}></div>
				        </div>


                <div className={` ${currentView === 2 ? "gallery-details" : " gallery-details hidden"}`}>
                  {galleryView}
                 
                
                </div>



              	{/* <div className={` ${currentView === 1 ? "fade-away" : " fade-away hidden"}`}></div> */}
              </div>


              <div className={` ${(currentView === 1 || currentView === 2) ? "audio-controls hidden" : " audio-controls "}`}>
              	

                  <Player audio={audio} color={artwork.color} isKiosk={props.isKiosk} artwork={artwork} isAutoPlay={isAutoPlay} audioFinished={resetLandingScreen}/>
              </div>
              
              
              
            </div>
            )}
      
      </div>
      </Orientation>

        <Orientation orientation={(props.isKiosk === undefined) ? 'landscape' : 'portrait'}  alwaysRender={false}>
          <Div100vh  dontresize="true" className="landscapeDiv">
            <p>For the optimal tour experience,<br/> please turn your phone to portrait mode.</p>
          </Div100vh>
        </Orientation>


      </DeviceOrientation>
    );

}