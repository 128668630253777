import React from 'react';

import Header from "../components/Header";
import Footer from "../components/Footer";

import Div100vh from 'react-div-100vh'
export default function NotFound(props) {


  return (
    <Div100vh  dontresize="true" className="notFound">
    	<Header hasBack={true} />
    	<div className="notFoundContent">
	    	<p>404 This Page does not exist</p>
	    	<a href="/">Take me Home</a>
    	</div>
    	<Footer />

  	</Div100vh>
    );
    



}



