import React, { useState, useEffect} from "react";
import { useInterval } from '../../helper/functions.js';

export default function Column1(props) {

	const height = props.height
	const [prompt1Data, setPrompt1Data] = useState(null);

 

 	  useEffect(() => {
	    if (height > 0) getPrompt1Data();
	  }, [height]);

 	  useInterval(() => {
	    getPrompt1Data();
	  }, 30000);


 	const getPrompt1Data = () => {
    

    fetch("/getPrompt0")
      .then((res) => res.json())
      .then((data) => {
        var hasChanged = false
        Object.keys(data).forEach(key => {
            if(prompt1Data !== null) {
              if(prompt1Data[key].percent !== data[key].percent) {
                hasChanged = true;
              } 
            }else {
            	hasChanged = true
            }

            
            var w = ((height * data[key].percent)/100) * 0.9

            if(w < 90) {
              data[key].width = 90;
            }else if(w < 120) {
              data[key].width = 120;
            }else if(w > 450) {
              data[key].width = 450;
            }else {
              data[key].width = w;
            }
            
            if(data[key].marginTop === 0) {
              data[key].marginTop = Math.floor(Math.random() * 20) - (20 +Math.floor(Math.random() * 30)) +'px';
            }
            
            if(data[key].percent < 20) {
              data[key].fontFamily = 18;
              data[key]["boxShadowMultiplier"]= 0.4
            }else if(data[key].percent < 24) {
              data[key].fontFamily = 20;
              data[key]["boxShadowMultiplier"]= 0.6
            }else if(data[key].percent < 49) {
              data[key].fontFamily = 26;
              data[key]["boxShadowMultiplier"]= 0.8
            }else if(data[key].percent < 74) {
              data[key].fontFamily = 30;
              data[key]["boxShadowMultiplier"]= 1
            } 
        });
        if(hasChanged) {
          setPrompt1Data(data)
        } 
      });
  } 

  
  //console.log("render col 1 ")

	return (
	<div className="column column-1">
        {(prompt1Data != null)  &&
         (<>
            <div className="pulse-box">
              <div className="loader" style={{ width: (prompt1Data.unsure.width)+'px', height: (prompt1Data.unsure.width)+'px', marginTop:'20px'}}>
                <span className="loader__inner" style={{boxShadow: `0 ${(prompt1Data.unsure.boxShadowMultiplier*-20)+'px'} 0 0 rgba(255, 255, 255, 0.1), ${(prompt1Data.unsure.boxShadowMultiplier*-15)+'px'} ${(prompt1Data.unsure.boxShadowMultiplier*10)+'px'} 0 0 rgba(255, 255, 255, 0.1), ${(prompt1Data.unsure.boxShadowMultiplier*15)+'px'} ${(prompt1Data.unsure.boxShadowMultiplier*10)+'px'} 0 0 rgba(255, 255, 255, 0.2)`,animationDelay:(prompt1Data.unsure.animationDelay)+"s", width: (prompt1Data.unsure.width)+'px', height: (prompt1Data.unsure.width)+'px'}}></span>
                <span className="loader__inner" style={{boxShadow: `${(prompt1Data.unsure.boxShadowMultiplier*15)+'px'} ${(prompt1Data.unsure.boxShadowMultiplier*-10)+'px'} 0 0 rgba(255, 255, 255, 0.25),${(prompt1Data.unsure.boxShadowMultiplier*-15)+'px'} ${(prompt1Data.unsure.boxShadowMultiplier*-10)+'px'} 0 0 rgba(255, 255, 255, 0.1), 0 ${(prompt1Data.unsure.boxShadowMultiplier*20)+'px'} 0 0 rgba(255, 255, 255, 0.3)`,animationDelay:(prompt1Data.unsure.animationDelay)+"s", width: (prompt1Data.unsure.width)+'px', height: (prompt1Data.unsure.width)+'px'}}></span>
                <p style={{fontSize: prompt1Data.unsure.fontFamily+'px', lineHeight: (prompt1Data.unsure.fontFamily*0.8)+'px' }}>UNSURE<br/><span style={{fontSize: (prompt1Data.unsure.fontFamily*0.75)+'px'}} >{Math.floor(prompt1Data.unsure.percent)}%</span></p>
          
              </div>

            </div>

            <div className="pulse-box">
              <div className="loader" style={{width: (prompt1Data.optimistic.width)+'px', height: (prompt1Data.optimistic.width)+'px', marginTop: prompt1Data.optimistic.marginTop, marginLeft: prompt1Data.optimistic.marginLeft }}>
                <span className="loader__inner" style={{boxShadow: `0 ${(prompt1Data.optimistic.boxShadowMultiplier*-20)+'px'} 0 0 rgba(255, 255, 255, 0.1), ${(prompt1Data.optimistic.boxShadowMultiplier*-15)+'px'} ${(prompt1Data.optimistic.boxShadowMultiplier*10)+'px'} 0 0 rgba(255, 255, 255, 0.1), ${(prompt1Data.optimistic.boxShadowMultiplier*15)+'px'} ${(prompt1Data.optimistic.boxShadowMultiplier*10)+'px'} 0 0 rgba(255, 255, 255, 0.2)`,animationDelay:(prompt1Data.optimistic.animationDelay)+"s", width: (prompt1Data.optimistic.width)+'px', height: (prompt1Data.optimistic.width)+'px'}}></span>
                <span className="loader__inner" style={{boxShadow: `${(prompt1Data.optimistic.boxShadowMultiplier*15)+'px'} ${(prompt1Data.optimistic.boxShadowMultiplier*-10)+'px'} 0 0 rgba(255, 255, 255, 0.25),${(prompt1Data.optimistic.boxShadowMultiplier*-15)+'px'} ${(prompt1Data.optimistic.boxShadowMultiplier*-10)+'px'} 0 0 rgba(255, 255, 255, 0.1), 0 ${(prompt1Data.optimistic.boxShadowMultiplier*20)+'px'} 0 0 rgba(255, 255, 255, 0.3)`,animationDelay:(prompt1Data.optimistic.animationDelay)+"s", width: (prompt1Data.optimistic.width)+'px', height: (prompt1Data.optimistic.width)+'px'}}></span>
                <p style={{fontSize: prompt1Data.optimistic.fontFamily+'px', lineHeight: (prompt1Data.optimistic.fontFamily*0.8)+'px' }}>OPTIMISTIC<br/><span style={{fontSize: (prompt1Data.optimistic.fontFamily*0.75)+'px'}} >{Math.floor(prompt1Data.optimistic.percent)}%</span></p>
          
              </div>
            </div>

            <div className="pulse-box">
              <div className="loader" style={{width: (prompt1Data.overwhelmed.width)+'px', height: (prompt1Data.overwhelmed.width)+'px', marginTop: prompt1Data.overwhelmed.marginTop, marginLeft: prompt1Data.overwhelmed.marginLeft }}>
                <span className="loader__inner" style={{boxShadow: `0 ${(prompt1Data.overwhelmed.boxShadowMultiplier*-20)+'px'} 0 0 rgba(255, 255, 255, 0.1), ${(prompt1Data.overwhelmed.boxShadowMultiplier*-15)+'px'} ${(prompt1Data.overwhelmed.boxShadowMultiplier*10)+'px'} 0 0 rgba(255, 255, 255, 0.1), ${(prompt1Data.overwhelmed.boxShadowMultiplier*15)+'px'} ${(prompt1Data.overwhelmed.boxShadowMultiplier*10)+'px'} 0 0 rgba(255, 255, 255, 0.2)`,animationDelay:(prompt1Data.overwhelmed.animationDelay)+"s", width: (prompt1Data.overwhelmed.width)+'px', height: (prompt1Data.overwhelmed.width)+'px'}}></span>
                <span className="loader__inner" style={{boxShadow: `${(prompt1Data.overwhelmed.boxShadowMultiplier*15)+'px'} ${(prompt1Data.overwhelmed.boxShadowMultiplier*-10)+'px'} 0 0 rgba(255, 255, 255, 0.25),${(prompt1Data.overwhelmed.boxShadowMultiplier*-15)+'px'} ${(prompt1Data.overwhelmed.boxShadowMultiplier*-10)+'px'} 0 0 rgba(255, 255, 255, 0.1), 0 ${(prompt1Data.overwhelmed.boxShadowMultiplier*20)+'px'} 0 0 rgba(255, 255, 255, 0.3)`,animationDelay:(prompt1Data.overwhelmed.animationDelay)+"s", width: (prompt1Data.overwhelmed.width)+'px', height: (prompt1Data.overwhelmed.width)+'px'}}></span>
                <p style={{fontSize: prompt1Data.overwhelmed.fontFamily+'px', lineHeight: (prompt1Data.overwhelmed.fontFamily*0.8)+'px' }}>OVERWHELMED<br/><span style={{fontSize: (prompt1Data.overwhelmed.fontFamily*0.75)+'px'}} >{Math.floor(prompt1Data.overwhelmed.percent)}%</span></p>
          
              </div>
            </div>

            <div className="pulse-box">
              <div className="loader" style={{width: (prompt1Data.curious.width)+'px', height: (prompt1Data.curious.width)+'px', marginTop: prompt1Data.curious.marginTop, marginLeft: prompt1Data.curious.marginLeft }}>
                <span className="loader__inner" style={{boxShadow: `0 ${(prompt1Data.curious.boxShadowMultiplier*-20)+'px'} 0 0 rgba(255, 255, 255, 0.1), ${(prompt1Data.curious.boxShadowMultiplier*-15)+'px'} ${(prompt1Data.curious.boxShadowMultiplier*10)+'px'} 0 0 rgba(255, 255, 255, 0.1), ${(prompt1Data.curious.boxShadowMultiplier*15)+'px'} ${(prompt1Data.curious.boxShadowMultiplier*10)+'px'} 0 0 rgba(255, 255, 255, 0.2)`,animationDelay:(prompt1Data.curious.animationDelay)+"s", width: (prompt1Data.curious.width)+'px', height: (prompt1Data.curious.width)+'px'}}></span>
                <span className="loader__inner" style={{boxShadow: `${(prompt1Data.curious.boxShadowMultiplier*15)+'px'} ${(prompt1Data.curious.boxShadowMultiplier*-10)+'px'} 0 0 rgba(255, 255, 255, 0.25),${(prompt1Data.curious.boxShadowMultiplier*-15)+'px'} ${(prompt1Data.curious.boxShadowMultiplier*-10)+'px'} 0 0 rgba(255, 255, 255, 0.1), 0 ${(prompt1Data.curious.boxShadowMultiplier*20)+'px'} 0 0 rgba(255, 255, 255, 0.3)`,animationDelay:(prompt1Data.curious.animationDelay)+"s", width: (prompt1Data.curious.width)+'px', height: (prompt1Data.curious.width)+'px'}}></span>
                <p style={{fontSize: prompt1Data.curious.fontFamily+'px', lineHeight: (prompt1Data.curious.fontFamily*0.8)+'px' }}>CURIOUS<br/><span style={{fontSize: (prompt1Data.curious.fontFamily*0.75)+'px'}} >{Math.floor(prompt1Data.curious.percent)}%</span></p>
          
              </div>
            </div>

            <div className="pulse-box">
              <div className="loader" style={{width: (prompt1Data.excited.width)+'px', height: (prompt1Data.excited.width)+'px', marginTop: prompt1Data.excited.marginTop, marginLeft: prompt1Data.excited.marginLeft }}>
                <span className="loader__inner" style={{boxShadow: `0 ${(prompt1Data.excited.boxShadowMultiplier*-20)+'px'} 0 0 rgba(255, 255, 255, 0.1), ${(prompt1Data.excited.boxShadowMultiplier*-15)+'px'} ${(prompt1Data.excited.boxShadowMultiplier*10)+'px'} 0 0 rgba(255, 255, 255, 0.1), ${(prompt1Data.excited.boxShadowMultiplier*15)+'px'} ${(prompt1Data.excited.boxShadowMultiplier*10)+'px'} 0 0 rgba(255, 255, 255, 0.2)`,animationDelay:(prompt1Data.excited.animationDelay)+"s", width: (prompt1Data.excited.width)+'px', height: (prompt1Data.excited.width)+'px'}}></span>
                <span className="loader__inner" style={{boxShadow: `${(prompt1Data.excited.boxShadowMultiplier*15)+'px'} ${(prompt1Data.excited.boxShadowMultiplier*-10)+'px'} 0 0 rgba(255, 255, 255, 0.25),${(prompt1Data.excited.boxShadowMultiplier*-15)+'px'} ${(prompt1Data.excited.boxShadowMultiplier*-10)+'px'} 0 0 rgba(255, 255, 255, 0.1), 0 ${(prompt1Data.excited.boxShadowMultiplier*20)+'px'} 0 0 rgba(255, 255, 255, 0.3)`,animationDelay:(prompt1Data.excited.animationDelay)+"s", width: (prompt1Data.excited.width)+'px', height: (prompt1Data.excited.width)+'px'}}></span>
                <p style={{fontSize: prompt1Data.excited.fontFamily+'px', lineHeight: (prompt1Data.excited.fontFamily*0.8)+'px' }}>EXCITED<br/><span style={{fontSize: (prompt1Data.excited.fontFamily*0.75)+'px'}} >{Math.floor(prompt1Data.excited.percent)}%</span></p>
          
              </div>
            </div>

            <div className="pulse-box">
              <div className="loader" style={{width: (prompt1Data.skeptical.width)+'px', height: (prompt1Data.skeptical.width)+'px', marginTop: prompt1Data.skeptical.marginTop, marginLeft: prompt1Data.skeptical.marginLeft }}>
                <span className="loader__inner" style={{boxShadow: `0 ${(prompt1Data.skeptical.boxShadowMultiplier*-20)+'px'} 0 0 rgba(255, 255, 255, 0.1), ${(prompt1Data.skeptical.boxShadowMultiplier*-15)+'px'} ${(prompt1Data.skeptical.boxShadowMultiplier*10)+'px'} 0 0 rgba(255, 255, 255, 0.1), ${(prompt1Data.skeptical.boxShadowMultiplier*15)+'px'} ${(prompt1Data.skeptical.boxShadowMultiplier*10)+'px'} 0 0 rgba(255, 255, 255, 0.2)`,animationDelay:(prompt1Data.skeptical.animationDelay)+"s", width: (prompt1Data.skeptical.width)+'px', height: (prompt1Data.skeptical.width)+'px'}}></span>
                <span className="loader__inner" style={{boxShadow: `${(prompt1Data.skeptical.boxShadowMultiplier*15)+'px'} ${(prompt1Data.skeptical.boxShadowMultiplier*-10)+'px'} 0 0 rgba(255, 255, 255, 0.25),${(prompt1Data.skeptical.boxShadowMultiplier*-15)+'px'} ${(prompt1Data.skeptical.boxShadowMultiplier*-10)+'px'} 0 0 rgba(255, 255, 255, 0.1), 0 ${(prompt1Data.skeptical.boxShadowMultiplier*20)+'px'} 0 0 rgba(255, 255, 255, 0.3)`,animationDelay:(prompt1Data.skeptical.animationDelay)+"s", width: (prompt1Data.skeptical.width)+'px', height: (prompt1Data.skeptical.width)+'px'}}></span>
                <p style={{fontSize: prompt1Data.skeptical.fontFamily+'px', lineHeight: (prompt1Data.skeptical.fontFamily*0.8)+'px' }}>SKEPTICAL<br/><span style={{fontSize: (prompt1Data.skeptical.fontFamily*0.75)+'px'}} >{Math.floor(prompt1Data.skeptical.percent)}%</span></p>
          
              </div>
            </div>

             <div className="pulse-box">
              <div className="loader" style={{width: (prompt1Data.somethingelse.width)+'px', height: (prompt1Data.somethingelse.width)+'px', marginTop: prompt1Data.somethingelse.marginTop, marginLeft: prompt1Data.somethingelse.marginLeft }}>
                <span className="loader__inner" style={{boxShadow: `0 ${(prompt1Data.somethingelse.boxShadowMultiplier*-20)+'px'} 0 0 rgba(255, 255, 255, 0.1), ${(prompt1Data.somethingelse.boxShadowMultiplier*-15)+'px'} ${(prompt1Data.somethingelse.boxShadowMultiplier*10)+'px'} 0 0 rgba(255, 255, 255, 0.1), ${(prompt1Data.somethingelse.boxShadowMultiplier*15)+'px'} ${(prompt1Data.somethingelse.boxShadowMultiplier*10)+'px'} 0 0 rgba(255, 255, 255, 0.2)`,animationDelay:(prompt1Data.somethingelse.animationDelay)+"s", width: (prompt1Data.somethingelse.width)+'px', height: (prompt1Data.somethingelse.width)+'px'}}></span>
                <span className="loader__inner" style={{boxShadow: `${(prompt1Data.somethingelse.boxShadowMultiplier*15)+'px'} ${(prompt1Data.somethingelse.boxShadowMultiplier*-10)+'px'} 0 0 rgba(255, 255, 255, 0.25),${(prompt1Data.somethingelse.boxShadowMultiplier*-15)+'px'} ${(prompt1Data.somethingelse.boxShadowMultiplier*-10)+'px'} 0 0 rgba(255, 255, 255, 0.1), 0 ${(prompt1Data.somethingelse.boxShadowMultiplier*20)+'px'} 0 0 rgba(255, 255, 255, 0.3)`,animationDelay:(prompt1Data.somethingelse.animationDelay)+"s", width: (prompt1Data.somethingelse.width)+'px', height: (prompt1Data.somethingelse.width)+'px'}}></span>
                <p style={{fontSize: prompt1Data.somethingelse.fontFamily+'px', lineHeight: (prompt1Data.somethingelse.fontFamily*0.8)+'px' }}>SOMETHING<br/>ELSE...<br/><span style={{fontSize: (prompt1Data.somethingelse.fontFamily*0.75)+'px'}} >{Math.floor(prompt1Data.somethingelse.percent)}%</span></p>
          
              </div>
            </div>

        </>
        )}

      </div>
	);
    



}



