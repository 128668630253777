import React, { useRef } from 'react';
import { useState, useEffect } from 'react';
import Div100vh from 'react-div-100vh';
import Header from "../components/Header";

import VideoPlayer from "../components/VideoPlayer";
import ReactGA from 'react-ga';
import DeviceOrientation, { Orientation } from 'react-screen-orientation'
import { ReactComponent as PlayIcon } from '../playericons/play.svg';

export default function VideoDetailsView(props) {
	
  const artwork = props.artwork
  const videoPath = artwork.videoPath;
  const [currentView, setCurrentView] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const videoElement = useRef(null);
  var timer = null;

  const  switchView = (i) => {
   
	   setCurrentView(i)
  }

  const togglePlay = () => {

  	if (!isPlaying) {
    	
		var element = document.getElementById("home-video");       
		
		if (element.mozRequestFullScreen) {
			element.mozRequestFullScreen();
		} else if (element.webkitRequestFullScreen) {
			element.webkitRequestFullScreen();
		} 
		videoElement.current.play();

		if(props.isKiosk) {

			ReactGA.event({
			  category: 'Kiosk',
			  action: 'Start Video Stop '+artwork.id
			});
		}else {
			ReactGA.event({
			  category: 'Web App',
			  action: 'Start Video Stop '+artwork.id
			});
		}

    }else{
    	videoElement.current.pause();
    	videoElement.current.currentTime = 0;
    }


    setIsPlaying(!isPlaying);


  };

  const onPaused = () => {
      if(props.isKiosk) {
        timer = setTimeout(function() {


          if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          }
          if (document.webkitExitFullscreen) {
              document.webkitExitFullscreen();
          }
          if (document.exitFullscreen) {
              document.exitFullscreen();
          }



         }, 20000);
      }
  }

  const onPlayed = () => {
    if(props.isKiosk) {
        clearTimeout(timer)
    }
  }

  const resetLandingScreen = () => {
    console.log("resetLandingScreen")


    
    /*if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    }
    if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
    }
    if (document.exitFullscreen) {
        document.exitFullscreen();
    }*/
  }


 const handleFullScreen = () => {
 	//console.log(document.fullscreen);
 	//console.log(document.mozFullScreen);
 	//console.log(document.webkitIsFullScreen);

 	if(document.fullscreen === false || document.mozFullScreen === false || document.webkitIsFullScreen === false) {
 		setIsPlaying(false);
 		videoElement.current.pause();
    	videoElement.current.currentTime = 0;
 		//console.log("iciiii")
 	}
 } 

 const handleExitFullScreen = () => {

 	setIsPlaying(false);
	videoElement.current.pause();
	videoElement.current.currentTime = 0;

 }

 const videoEnded = () => {
 	//alert("ended")
 	if (document.mozCancelFullScreen) {
	    document.mozCancelFullScreen();
	}
	if (document.webkitExitFullscreen) {
	    document.webkitExitFullscreen();
	}
	if (document.exitFullscreen) {
	    document.exitFullscreen();
	}


 }



  useEffect(() => {
   
   if(props.isKiosk) {
      document.addEventListener("fullscreenchange", handleFullScreen);
      document.addEventListener("mozfullscreenchange", handleFullScreen);
      document.addEventListener("webkitfullscreenchange", handleFullScreen);
      document.getElementById("home-video").addEventListener("webkitendfullscreen", handleExitFullScreen);
      document.getElementById("home-video").addEventListener("ended", videoEnded);
      document.getElementById("home-video").addEventListener("pause", onPaused);
      document.getElementById("home-video").addEventListener("play", onPlayed);
	
      return () => {

        document.removeEventListener('fullscreenchange', handleFullScreen);
        document.removeEventListener('mozfullscreenchange', handleFullScreen);
        document.removeEventListener('webkitfullscreenchange', handleFullScreen);
        document.getElementById("home-video").removeEventListener("webkitendfullscreen", handleExitFullScreen);
        document.getElementById("home-video").removeEventListener("ended", videoEnded);
        document.getElementById("home-video").removeEventListener("pause", onPaused);
        document.getElementById("home-video").removeEventListener("play", onPlayed);
      };
    }
      
  }, []);

  //style={{display: isPlaying ? 'block' : 'none'}}
  let backgroundImage = artwork.thumbnailPath;

  if(props.isKiosk) {
  	let backgroundImageKiosk = artwork.imgPathKiosk;

    return (
      <Div100vh  dontresize="true" className="landingScreenKiosk" onClick={togglePlay}  style={{ backgroundImage: `url(${backgroundImageKiosk})` }} >
        <div>
          <h1>Learn more about women teaching women,<br/>from pastels to Anishnabe basket weaving.</h1>
            <PlayIcon className="playIcon" fill="#fff" />
          <h1>Tap the screen for a 2-minute video.</h1>

          <div className="video-player" style={{display: isPlaying ? 'block' : 'none'}}>
		       <video ref={videoElement} id="home-video" >
			        <source src={videoPath} type="video/mp4"/>
			   </video>
		    </div>
        </div>
      </Div100vh>

    )

  }

  return (
    	<DeviceOrientation lockOrientation={"default"}>
        <Orientation orientation="portrait" alwaysRender={true}>
          <div  className="audiodetails" style={{minHeight:'100vh'}}>
       

          {(props.isKiosk === undefined)  &&
           (<Header hasBack={true}  isFromApp={props.isFromApp}/>)}
        
           {(artwork != null)  &&
           (
            <div className="container">

            	<div className="audiodetails-toppart">
	              

	              <div className="audio-toggler">
                  <div onClick={function(e){switchView(0)} } className={` ${currentView === 0 ? "active" : ""}`} style={{ color: currentView === 0 ? "#fff" : artwork.color, borderColor:artwork.color, background: (currentView === 1 || currentView === 2) ? "#fff" : artwork.color}}>Video</div>
                  
                
                  <div onClick={function(e){switchView(1)} } className={` ${currentView === 1 ? "active" : ""}`} style={{ color: currentView === 1 ? "#fff" : artwork.color, borderColor:artwork.color, background: (currentView === 0  || currentView === 2) ? "#fff" : artwork.color}}>Text</div>
              </div>
	            </div>
              <div className={` ${currentView === 1 ? "audiodetails-image-container margins" : " audiodetails-image-container "}`}>
              	

                <div className={` ${currentView === 0 ? "" : "hidden"}`}>
                  
                  <img src={backgroundImage} className="image-details" />
                  <div className="descriptionAudio" style={{ color: artwork.color }}>
                  <h1>{artwork.name}, {artwork.year}</h1>
                  <h3>{artwork.artist}</h3>
                  </div>
                </div>


              	<div className={` ${currentView === 1 ? "text-details" : " text-details hidden"}`}>
                  <div className="descriptionAudio" style={{ color: artwork.color }}>
                    <h1>{artwork.name}, {artwork.year}</h1>
                    <h3>{artwork.artist}</h3>
                  </div>
				          <div className="text-content-details"   dangerouslySetInnerHTML={{ __html: artwork.description }}></div>
				        </div>

              </div>


              <div className={` ${(currentView === 1) ? "audio-controls hidden" : " audio-controls "}`}>
              	


              	<VideoPlayer videoPath={videoPath} color={artwork.color} artwork={artwork}  />

              </div>



              
              
              
            </div>
            )}
      
      </div>
      </Orientation>

       


      </DeviceOrientation>
    );

}



/*

 <Orientation orientation="landscape"  alwaysRender={false}>
          <Div100vh  dontresize="true" className="landscapeDiv">
            <p>For the optimal tour experience,<br/> please turn your phone to portrait mode.</p>
            
            <div className="video-player" style={{display: isPlaying ? 'block' : 'none'}}>
                 <video ref={videoElement} id="home-video" >
                    <source src={videoPath} type="video/mp4"/>
                 </video>
            </div>

          </Div100vh>
        </Orientation>

        */