import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Routes, Route, } from 'react-router-dom';
import './css/index.css';
import Home from './views/Home';import ProjectDetailsView from "./views/ProjectDetailsView";
import ArtworkDetailsView from "./views/ArtworkDetailsView";
import KioskDetailsView from "./views/KioskDetailsView";
import QuizzDetailsView from "./views/QuizzDetailsView";
import Visualisation from "./views/Visualisation";
import Visualisation2 from "./views/Visualisation2";
import NotFound from './views/NotFound';

import store from 'store';
import ReactGA from 'react-ga';


ReactGA.initialize('UA-214568593-1');
ReactGA.pageview(window.location.pathname + window.location.search);



/*const { v4: uuidv4 } = require('uuid');

const userUuid = store.get("userUuid");

if(userUuid  === undefined) store.set('userUuid', uuidv4());*/

const routing = (
	<Router>
	  <Routes>

	      <Route exact path="/" element={<Home />} />
	      <Route exact path="/stop/:id" element={<ArtworkDetailsView />} />
	      <Route exact path="/project/:id" element={<ProjectDetailsView />} />
	      <Route exact path="/prompt/:id" element={<QuizzDetailsView />} />
	      <Route exact path="/projection-test" element={<Visualisation2 />} />
	      <Route exact path="/projection" element={<Visualisation />} />
	      <Route exact path="/kiosk/:id" element={<KioskDetailsView />} />

	      <Route path='*' exact={true} element={<NotFound />} />

	  </Routes>
  	</Router>
)

ReactDOM.render(routing, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
