import React from 'react';
import artworks from '../datas/Artworks.js';
import AudioDetailsView from './AudioDetailsView';
import Feature2DetailsView from './Feature2DetailsView';
import Feature8DetailsView from './Feature8DetailsView';
import VideoDetailsView from './VideoDetailsView';
import NotFound from '../views/NotFound';
import { useParams } from "react-router-dom";

import { useLocation } from "react-router-dom";

export default function ArtworkDetailsView(props) {
	const artwork = artworks[useParams().id-1];
	const { state } = useLocation();
	const isFromApp = state !== null ? true : false;



	if(artwork === undefined) {
	    return (
	      <NotFound />
	      )
	}

	if(artwork != null && artwork.interactive === 2) {
		return (<Feature2DetailsView artwork = {artwork}  isFromApp={isFromApp} />);
	}
	if(artwork != null && artwork.interactive === 8) {
		return (<Feature8DetailsView artwork = {artwork}  isFromApp={isFromApp}  />);
	}
	if(artwork != null &&  artwork.videoPath !== undefined) {
		return (<VideoDetailsView artwork = {artwork} isFromApp={isFromApp} />);
	}


	return (<AudioDetailsView artwork = {artwork} isFromApp={isFromApp} />);
  


}



