import React from 'react';
import { useState, useEffect } from 'react';
import DeviceOrientation, { Orientation } from 'react-screen-orientation'
import Header from "../components/Header";
import Footer from "../components/Footer";
import ArtworkLoopItem from "../components/ArtworkLoopItem";
import artworks from '../datas/Artworks.js';
import store from 'store';
import { use100vh } from 'react-div-100vh'
import Div100vh from 'react-div-100vh'

import animateScroll from 'animated-scroll-to';

export default function Home(props) {

  const [imagesLoaded, setImagesLoaded] = useState(false)
  const [animationDone, setAnimationDone] = useState(false)
  const fullHeight = use100vh()
  const [heightLoadingScreen,setHeightLoadingScreen] = useState(0);
  //const loaded = 0;
  const loaded = store.get("hasLoaded");
  const [hideScrollDown, setHideScrollDown] = useState(false)

  useEffect(() => {
    //const loaded = store.get("hasLoaded");
    
    


    if(loaded) {
      setHeightLoadingScreen(0);

      
    
    } else {
      //document.documentElement.scrollTo(0, 0);
      setHeightLoadingScreen(fullHeight ? (fullHeight - 51) : 'calc( 100vh - 51px)');

      const images = []
      images.push("/logo.jpg");
      images.push("/img/loading-image.jpg");
      images.push("/img/exhibit-tittle.png");
      images.push("/loader.gif");
      for (var i in artworks) {
        if(Array.isArray(artworks[i].imgPath)) {
          for (var j in artworks[i].imgPath) {
            images.push(artworks[i].imgPath[j])
          }
        }else {
          images.push(artworks[i].imgPath)
        }
        
      } 

      let index=0
      images.forEach((image) => {
        new Image().src = image
        index ++;
        if (index === images.length) {
          
          
          setTimeout(() => {
            setImagesLoaded(true)
            store.set('hasLoaded', true);

            setTimeout(() => {
              setAnimationDone(true)
            }, 1500);
            
          }, 3000);

        }
      });

    }


    function handleScroll() {
        if(animationDone || loaded){
          setHideScrollDown(true)
        }
        
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)


    

  },[fullHeight]);

  
  const scrollDown = (e) => {
    animateScroll(fullHeight - 50, { minDuration: 1500 });
    setHideScrollDown(true)
  }

  const artworksView = artworks.map((artwork, index) => {

      return (<ArtworkLoopItem artwork={artwork}  key={artwork.id}/>)
    
  })



  return (
    <DeviceOrientation lockOrientation={'portrait'} className="homeContainer" >
      <Orientation orientation='portrait' alwaysRender={false}>
        
      <Header />
        <div className="homeContent fadeIn"  >

          {(!animationDone)  &&
          (<div style={{ height: heightLoadingScreen, marginTop: imagesLoaded ? -heightLoadingScreen : "0"  }} className={`loadingScreen ${(imagesLoaded && !animationDone) ? "scrollUp" : ""}`}>
            <div className="exhibit-image">
              <img src="/img/loading-image.jpg" alt="loading artwork"/>
            </div>
            <div className="exhibit-tittle">
              <img src="/img/exhibit-tittle.png" alt="exhibit title"/>
            </div>
           
          </div>

          )}
          <div className="scrollInfo"  style={{backgroundColor: artworks[0].color, color:"#fff"}}>Continue to scroll down to access<br/>the full playlist</div>
          <div className="artworksList" id="artworksList" >
            {artworksView}
          </div>
         
        </div>

        {(animationDone || loaded)  &&
        
        (<div className={`arrow-container animated fadeInDown ${hideScrollDown ? "fadeOut" : ""}`}   onClick={scrollDown}>
          <div className="arrow-2">
            <img src="/scroll-icon.png" alt="scroll icon" />
          </div>
          <div className="arrow-1 animated hinge infinite zoomInScroll"></div>
        </div>)
        }

        <Footer />


      </Orientation>

      <Orientation orientation='landscape'  alwaysRender={false}>
        <Div100vh  dontresize="true" className="landscapeDiv">
          <p>For the optimal tour experience,<br/> please turn your phone to portrait mode.</p>
        </Div100vh>
      </Orientation>


    </DeviceOrientation>
    );
    



}



