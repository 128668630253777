export default
[
		{
			"id": 0,
			"header": "Prompt 1",
			"question":"<p>Right now, I'm feeling...</p>",
			"type":"quizz",
			"color":"#293E37",
			"beforeArtwork":1,
			"options": 
			[
				"Curious", 
				"Excited",
				"Skeptical", 
				"Optimistic",
				"Unsure",
				"Overwhelmed",
				"Something else..."
			]
		},
		{
			"id": 1,
			"header": "Prompt 2",
			"question":"<p>So far, I feel most interested in stories about...</p>",
			"type":"quizz",
			"color":"#2c3863",
			"beforeArtwork":1,
			"options": 
			[
				"Training", 
				"Innovation",
				"Business savvy",
				"Overcoming barriers",
				"Fame",
				"Something else..."
			]
		},
		{
			"id": 2,
			"header": "Prompt 3",
			"question":"<p>The stories I've experienced today made me reflect on...</p>",
			"type":"quizz",
			"color":"#84716D",
			"beforeArtwork":1,
			"options": 
			[
				"Missing narratives", 
				"Biases in art history",
				"How much is left to discover", 
				"Women's unique journeys to success",
				"Something else..."
			]
		},
		{
			"id": 3,
			"header": "Prompt 4",
			"question":"<p>Imagine a self-portrait that tells your story.<br/>What three things would you choose to represent yourself to viewers now and in the future?</p>",
			"type":"input",
			"beforeArtwork":2,
			"color":"#554657",
			"options": []
		}
	]
