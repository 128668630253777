import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { useNavigate } from "react-router-dom";

//import ReactGA from 'react-ga';

export default function ArtworkLoopItem(props) {

	let navigate = useNavigate();
	const artwork = props.artwork

	const openUrl = (e) => {
		

       navigate("/stop/"+artwork.id, { state: { isFromApp:"true" }})
       //ReactGA.pageview("/stop/"+artwork.id);

	}

	return (

		<a  href={"/stop/"+artwork.id} onClick={function(e) {e.preventDefault(); openUrl()}}>
			<div className="artowrkLoopItem" style={{backgroundColor: artwork.color}}>
					<div className="artowrkImage">
						{(artwork.imgPath instanceof Array)  ?
						(
							

							<div className="slide-container" >
						        <Slide autoplay={true} duration={3000} transitionDuration={700} arrows={false}>
						         {artwork.imgPath.map((slideImage, index)=> (
						            <div className="each-slide" key={index}>
						              
						              <img src={slideImage} alt={artwork.name} />
						            </div>
						          ))} 
						        </Slide>
						      </div>



						):(
							<img src={artwork.imgPath} alt={artwork.name}/>
						)}
					</div>
				
			    	<div className="artworkDescription">
			    		{artwork.audioPath  !== undefined && (
							<div className="headphoneicon">
								<img src="/headphone.png" alt="icon headphone"/>
								<p>{artwork.audioLength}</p>
							</div>
		            	)}
		            	{artwork.videoPath  !== undefined && (
							<div className="headphoneicon">
								<img src="/videoicon_2.png" alt="icon headphone"/>
								<p>{artwork.videoLength}</p>
							</div>
		            	)}

		            	{artwork.interactive  !== undefined && (
							<div className="interactiveicon">
								<img src="/interactiveIcon.svg" alt="icon interactive"/>
								<p>Interactive</p>
							</div>
		            	)}

			    		<h2>{artwork.name}, {artwork.year}</h2>
	
			    		<h3>{artwork.artist}</h3>
			    	</div>
				
			</div>
		</a>
	);
    



}



