import React, { useState, useEffect} from "react";
import { useInterval } from '../../helper/functions.js';

export default function Column3(props) {

	const height = props.height
	const [prompt3Data, setPrompt3Data] = useState(null);


	useEffect(() => {
		if (height > 0) getPrompt3Data();
	}, [height]);

	useInterval(() => {
		getPrompt3Data();
	}, 30000);

	const getPrompt3Data = () => {
    


    fetch("/getPrompt2")
      .then((res) => res.json())
      .then((data) => {

        var hasChanged = false
        Object.keys(data).forEach(key => {

            var w = (((height+50) * data[key].percent)/100)
            if(prompt3Data !== null) {
              if(prompt3Data[key].percent !== data[key].percent) {
                hasChanged = true;
              }
              
            }
            if(prompt3Data === null) {
              hasChanged = true
            }

            data[key].width = w;

            if(data[key].percent < 20) {
              data[key].fontFamily = 18;
            }else  if(data[key].percent < 24) {
              data[key].fontFamily = 20;
            }else if(data[key].percent < 49) {
              data[key].fontFamily = 26;
            }else if(data[key].percent < 74) {
              data[key].fontFamily = 30;
            } 
        });

        if(hasChanged) {
          setPrompt3Data(data)
        }
      });



  } 

 
  //console.log("render col 3 ")
  

  return (
    <div className="column column-3">
        {(prompt3Data != null)  &&
          (<>
            <div className="prompt3DataItem" >
              <div className="prompt3DataItemContent" style={{animationDelay:(prompt3Data.womensuniquejourneystosuccess.animationDelay)+"s",height:(prompt3Data.womensuniquejourneystosuccess.width)+'px', marginLeft: `calc(50% - ${(prompt3Data.womensuniquejourneystosuccess.percent*1.5)/2+'%'})`, width:(prompt3Data.womensuniquejourneystosuccess.percent*1.5)+'%'}}></div>
              
              <div className="prompt3DataItemText" style={{height:(prompt3Data.womensuniquejourneystosuccess.width)+'px'}}>
                <p style={{fontSize: prompt3Data.womensuniquejourneystosuccess.fontFamily+'px', lineHeight: (prompt3Data.womensuniquejourneystosuccess.fontFamily*0.8)+'px'}}>WOMEN'S UNIQUE<br/>JOURNEYS TO SUCCESS<br/><span style={{fontSize: (prompt3Data.womensuniquejourneystosuccess.fontFamily*0.75)+'px'}} >{Math.floor(prompt3Data.womensuniquejourneystosuccess.percent)}%</span></p>
              </div>
            </div>

            <div className="prompt3DataItem" >
              <div className="prompt3DataItemContent" style={{animationDelay:(prompt3Data.missingnarratives.animationDelay)+"s",height:(prompt3Data.missingnarratives.width)+'px', marginLeft: `calc(50% - ${(prompt3Data.missingnarratives.percent*1.5)/2+'%'})`, width:(prompt3Data.missingnarratives.percent*1.5)+'%' }}></div>
              
              <div className="prompt3DataItemText" style={{height:(prompt3Data.missingnarratives.width)+'px'}}>
                <p style={{fontSize: prompt3Data.missingnarratives.fontFamily+'px', lineHeight: (prompt3Data.missingnarratives.fontFamily*0.8)+'px'}}>MISSING<br/>NARRATIVES<br/><span style={{fontSize: (prompt3Data.missingnarratives.fontFamily*0.75)+'px'}} >{Math.floor(prompt3Data.missingnarratives.percent)}%</span></p>
              </div>
            </div>

            <div className="prompt3DataItem" >
              <div className="prompt3DataItemContent" style={{animationDelay:(prompt3Data.biasesinarthistory.animationDelay)+"s",height:(prompt3Data.biasesinarthistory.width)+'px', marginLeft: `calc(50% - ${(prompt3Data.biasesinarthistory.percent*1.5)/2+'%'})`, width:(prompt3Data.biasesinarthistory.percent*1.5)+'%' }}></div>
              
              <div className="prompt3DataItemText" style={{height:(prompt3Data.biasesinarthistory.width)+'px'}}>
                <p style={{fontSize: prompt3Data.biasesinarthistory.fontFamily+'px', lineHeight: (prompt3Data.biasesinarthistory.fontFamily*0.8)+'px'}}>BIASES IN<br/>ART HISTORY<br/><span style={{fontSize: (prompt3Data.biasesinarthistory.fontFamily*0.75)+'px'}} >{Math.floor(prompt3Data.biasesinarthistory.percent)}%</span></p>
              </div>
            </div>

            <div className="prompt3DataItem" >
              <div className="prompt3DataItemContent" style={{animationDelay:(prompt3Data.howmuchislefttodiscover.animationDelay)+"s",height:(prompt3Data.howmuchislefttodiscover.width)+'px', marginLeft: `calc(50% - ${(prompt3Data.howmuchislefttodiscover.percent*1.5)/2+'%'})`, width:(prompt3Data.howmuchislefttodiscover.percent*1.5)+'%'}}></div>
              
              <div className="prompt3DataItemText" style={{height:(prompt3Data.howmuchislefttodiscover.width)+'px'}}>
                <p style={{fontSize: prompt3Data.howmuchislefttodiscover.fontFamily+'px', lineHeight: (prompt3Data.howmuchislefttodiscover.fontFamily*0.8)+'px'}}>HOW MUCH IS<br/>LEFT TO DISCOVER<br/><span style={{fontSize: (prompt3Data.howmuchislefttodiscover.fontFamily*0.75)+'px'}} >{Math.floor(prompt3Data.howmuchislefttodiscover.percent)}%</span></p>
              </div>
            </div>

            <div className="prompt3DataItem" >
              <div className="prompt3DataItemContent" style={{animationDelay:(prompt3Data.somethingelse.animationDelay)+"s",height:(prompt3Data.somethingelse.width)+'px', marginLeft: `calc(50% - ${(prompt3Data.somethingelse.percent*1.5)/2+'%'})`, width:(prompt3Data.somethingelse.percent*1.5)+'%'}}></div>
              
              <div className="prompt3DataItemText" style={{height:(prompt3Data.somethingelse.width)+'px'}}>
                <p style={{fontSize: prompt3Data.somethingelse.fontFamily+'px', lineHeight: (prompt3Data.somethingelse.fontFamily*0.8)+'px'}}>SOMETHING ELSE...<br/><span style={{fontSize: (prompt3Data.somethingelse.fontFamily*0.75)+'px'}} >{Math.floor(prompt3Data.somethingelse.percent)}%</span></p>
              </div>
            </div>
          </>
        )}
      </div>
    );
    



}



