import React, { useState, useEffect } from "react";
import ReactWordcloud from 'react-wordcloud';
import { useInterval } from '../../helper/functions.js';

import Column4TextPrompt from "./Column4TextPrompt"


let options = {
	rotations: 1,
	rotationAngles: [0],
	fontSizes: [19,19],
	fontFamily:"sans-serif"
};

let reactWordcloudCallbacks = {
	getWordColor: word => "#fff",
}


export default function Column4(props) {

	const [values, setValues] = useState({
		prompt4Data: [],
		prompt4Cloud: [],
		latestPrompt4: "",
		hasNewPrompt4:false
	});


	useEffect(() => {
		getPrompt4Data();
	}, []);

	useInterval(() => {
		getPrompt4Data();
	}, 30000);

	useInterval(() => {
		updatePrompt4();
	}, 13000);


	const getPrompt4Data = () => {

		fetch("/getPrompt3")
		  .then((res) => res.json())
		  .then((data) => {
		    if(data.length > 0){
		      if(data.length !== values.prompt4Data.length) {
		        //Nouvelle entry
		        let text = data[data.length-1].text;
		        if (text.length > 200) {
		          text = data[data.length-1].text.substring(0, 230) + '...'
		        }
		        var newArray  = data.map(object => ({ ...object }));
		        newArray.splice(-1);
		        
		        for (var i = 0; i < newArray.length; i++) {
		            let t = newArray[i].text
		            if ( t.length > 20) {
		              newArray[i].text =  t.substring(0, 20) + '...'
		            }
		        }

		        var data4 = data.map(object => ({ ...object }));


		        setValues({
				    ...values,                                // spreading the unchanged values
				    latestPrompt4:text, 
				    hasNewPrompt4:true,  
				    prompt4Cloud:newArray,
				    prompt4Data:data4
				});
		      }
		    }
		  });
	} 

	const updatePrompt4 = () => {
		//console.log("updatePrompt4"+hasNewPrompt4)

		let prompt4Data = values.prompt4Data
		if(!values.hasNewPrompt4 && prompt4Data.length) {
		  const randomElement = Math.floor(Math.random() * values.prompt4Data.length);
		  //console.log(randomElement)
		  

		  let text = prompt4Data[randomElement].text;
		  if (text.length > 200) {
		      text = prompt4Data[randomElement].text.substring(0, 230) + '...'
		  }

		  var newArray = prompt4Data.map(object => ({ ...object }));
		  newArray.splice(randomElement, 1);

		  for (var i = 0; i < newArray.length; i++) {
		      let t = newArray[i].text
		      if ( t.length > 20) {
		        newArray[i].text =  t.substring(0, 20) + '...'
		      }
		  }

		  setValues({
		    ...values,                                // spreading the unchanged values
		    latestPrompt4:text, 
		    prompt4Cloud:newArray
		  });


		}else {

		  setValues({
		    ...values,                                // spreading the unchanged values  
		    hasNewPrompt4:false,  
		  });
		}

	}




  //console.log("render col 4")

  return (
    <div className="column column-4">
        <div className="wordCloud">
          <ReactWordcloud maxWords={50} words={values.prompt4Cloud} options={options} callbacks={reactWordcloudCallbacks}/>
        </div>

        <div className="front-view">
            <div className="line"></div>
            <div className="quote">
              {values.latestPrompt4}
            </div>
          
            <div className="quote2"> </div>

            <div className="panel">
            <div className="promptText">
            </div>
                <Column4TextPrompt />
            </div>
          

        </div>


      </div>
    );
    



}



